import React from 'react'
import './HelpPopup.css'

export default function HelpPopup(props) {
    return (
        <div className='helpPopupPageWrapper'>
            <div className='helpPopupPageBackdrop' onClick={props.closeHelpPopup}/>
            <div className='exitCaseContainer'>
                <div className='exitCaseHeaderContainer'>
                    <p className='exitCaseHeaderText'>Fill-in-the-blank Rules</p>
                </div>
                <div className='helpPopupBodyContainer'>
                    <p className='helpPopupBodyText'>
                    Rules for fill in the blank: (Note: This window will NOT be there on the real test, so do not depend on this as a reference)<br /><br />
                    This is not included on the real test. This is for education purposes only. You can hide this by going into account and unchecking the box labeled "hide fill in the blank rules"<br /><br />
                    <strong>#1</strong> - If giving a drug, give drug name, not classes (example: give hydrochlorothiazide rather than "antihypertensive").<br /><br />
                    <strong>#2</strong> - If giving a drug, give the route of the drug : Oral (by mouth), inhalation, sublingual, intravenous, intramuscular, subcutaneous, intradermal, topical) Try not to abbreviate<br /><br />
                    <strong>#3</strong> - Imaging studies: Must include anatomic site (example: Chest xray instead of xray)<br /><br />
                    <strong>#4</strong> - Avoid or limit use of abbreviations due to lack of standardization. We accept some standard abbreviations, but it would be safer to spell it out and not abbreviate on the test!<br /><br />
                    <strong>#5</strong> - Protocols will not be accepted as correct because they are often not universal.<br /><br />
                    <strong>#6</strong> - Urgency: Include “stat” when a procedure or diagnostic test is needed within 30 minutes, regardless of clinical setting, including the emergency department.<br /><br />
                    <strong>#7</strong> - Referrals and consults: If the correct plan of action requires a specialty referral and/or consultation, in addition to the type of specialist you must specify the appropriate procedure or management step the specialist will perform (for example, “refer to psychiatry for cognitive-behavioral therapy”).<br /><br />

                    Note that just like in the real test, there might be 2 phrases required in a fill-in-the-blank answer. As with the example above with referral to psychiatry, the first phrase that will be matched is "psychiatry" and the second phrase that will be matched is "cognitive behavioral therapy." In the feedback section, there will be a marker Additional phrase required which will show you the second phrase that must be matched.<br /><br />

                    Another example of 2 phrases that must be matched is with fill in the blank medications which require the route of administration. The first phrase would be the medication such as lisinopril. The second phrase would be the route which would be oral or by mouth or PO (though try to limit abbreviation usage). The correct response would be lisinopril by mouth.<br />
                    </p>
                </div>
                <div className='exitCaseFooterContainer'>
                    <button className='exitCaseFooterButton' onClick={props.closeHelpPopup}>Close</button>
                </div>
            </div>
        </div>
    )
}
