import React from 'react'
import CheckboxAnswer from './AnswerComponents/CheckboxAnswer/CheckboxAnswer'
import InputAnswer from './AnswerComponents/InputAnswer/InputAnswer'
import './SimulationBody.css'

export default function SimulationBody(props) {

    const getPreviousQuestions = () => {
        let questionsArray = []
        for(let i = 1; i < props.currentQuestion; ++i) {
            questionsArray.push(
                <div key={i}>
                    <p className='simulationBodyPrevQuestionsTitle'>Question {i}</p>
                    <p className='simulationBodyPrevQuestionsText'>{props.simulationData.qo[i - 1].QUESTION}</p>
                    {((i + 1) < props.currentQuestion) && <br />}
                    {(props.simulationData.qo[i - 1].ADDITIONALINFO !== "") &&
                        <div>
                            <p className='simulationBodyPrevQuestionsText'>{props.simulationData.qo[i - 1].ADDITIONALINFO}</p>
                            {((i + 1) < props.currentQuestion) && <br />}
                        </div>
                    }
                </div>
            )
        }
        return questionsArray
    }

    return (
        <div className='simulationBodyContainer'>
            <h3 className='simulationBodyHeader highlightedHeader'>Case {props.caseNumber}, Part {props.currentQuestion} of {props.simulationData.qo.length}</h3>
            <p className='simulationBodyText'>{props.simulationData.CASEPRESENTATION}</p>
            <table className='simulationBodyTable'>
                <tbody>
                    {props.simulationData.INITIALINFO.map((value, index) => (
                        <tr className='simulationBodyTableRow' key={`bodyRow${index}`}>
                            <td className='simulationBodyTableColumn'>{value.vital}</td>
                            <td className='simulationBodyTableColumn'>{value.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {(props.simulationData.qo[0].ADDITIONALINFO !== "") &&
                <p className='simulationBodyText'>{props.simulationData.qo[0].ADDITIONALINFO}</p>
            }
            {(props.currentQuestion > 1) &&
                <div className='simulationBodyPrevQuestionsContainer' style={{marginTop: props.simulationData.qo[0].ADDITIONALINFO === "" ? 20 : 0}}>
                    <p className='simulationBodyPrevQuestionsText'>PREVIOUS QUESTIONS RELATED TO THIS CASE:</p>
                    {getPreviousQuestions().map((value) => (
                        value
                    ))}
                </div>
            }
            <h3 id="beginQuestionElement" className='simulationBodyHeader highlightedHeader'>Part {props.currentQuestion} of {props.simulationData.qo.length}</h3>
            {(props.simulationData.qo[props.currentQuestion - 1].ADDITIONALINFO !== "") &&
                <p className='simulationBodyText'>{props.simulationData.qo[props.currentQuestion - 1].ADDITIONALINFO}</p>
            }
            {(props.simulationData.qo[props.currentQuestion - 1].ADDITIONALIMAGE !== "") &&
                <img className='simulationBodyImage' src={'https://app.cdmcases.com/' + props.simulationData.qo[props.currentQuestion - 1].ADDITIONALIMAGE}/>
            }
            <div className='simulationBodyHeaderWrapper'>
                <h3 className='simulationBodyHeader' style={{margin: '0px'}}>Question {props.currentQuestion}</h3>
                {props.simulationData.qo[props.currentQuestion - 1].BLANKTYPE && <svg onClick={props.showHelpPopup} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" style={{width: '20px', alignSelf: 'center', marginLeft: '10px', cursor: 'pointer'}} className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#003D7A" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>}
            </div>
            {props.simulationData.qo[props.currentQuestion - 1].BLANKTYPE ?
                <InputAnswer
                    modifyInputAnswer={props.modifyInputAnswer}
                    simulationData={props.simulationData}
                    answerList={props.answerList}
                    currentQuestion={props.currentQuestion}
                />
            :
                <CheckboxAnswer 
                    updateCheckboxAnswer={props.updateCheckboxAnswer}
                    selectedCheckboxAmount={props.selectedCheckboxAmount}
                    simulationData={props.simulationData}
                    currentQuestion={props.currentQuestion}
                    answerList={props.answerList}
                />
            }
        </div>
    )
}
