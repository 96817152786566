import React from 'react'
import './SimulationHeader.css'

export default function SimulationHeader(props) {

    const formatTimeRemaining = (timeRemaining) => {
        let hours = Math.floor(timeRemaining / 3600)
        let minutes = Math.floor(timeRemaining / 60)
        let seconds = timeRemaining % 60

        if (hours < 10) {
            hours = `0${hours}`
        }
        if (minutes < 10) {
            minutes = `0${minutes}`
        }
        if (seconds < 10) {
            seconds = `0${seconds}`
        }
        return `${hours}:${minutes}:${seconds}`
    }

    return (
        <div className='simulationHeaderContainer' id='headerContainer'>
            <div className='simulationHeaderLeftContentWrapper'>
                <h3 className='simulationHeaderText'>Question {props.currentQuestion} of {props.simulationData.TOTALNUMQUESTIONS}</h3>
            </div>
            <div className='simulationHeaderCenterContent'>
                <h3 className='simulationHeaderText'>CDM Practice Exam</h3>
            </div>
            <div className='simulationHeaderRightContentWrapper'>
                <h3 className='simulationHeaderText'>Time remaining for this session:</h3>
                {props.timedExam ?
                    <h3 className='simulationHeaderText'>{formatTimeRemaining(props.timeRemaining)}</h3>
                :
                    <h3 className='simulationHeaderText'>Untimed</h3>
                }
            </div>
            <button className='simulationHeaderLabPanelsButton' onClick={props.displayLabPanels}>LAB PANELS</button>
        </div>
    )
}
