import React, { Component } from 'react'
import LoginPage from './Components/LoginPage/LoginPage'
import './Login.css'
import './CCSCasesStyles.css'
import logo from '../../assets/cdmCases.png'
import UnauthenticatedHandler from '../UnauthenticatedHandler/UnauthenticatedHandler'
import ForgotPassword from './Components/ForgotPassword/ForgotPassword'
import TermsOfService from './Components/TermsOfService/TermsOfService'
import DemoSignup from './Components/DemoSignup/DemoSignup'
import fetchSubmitLogs from '../../Functions/FetchSubmitLogs'
import logFetchError from '../../Functions/LogFetchError'

export default class Login extends Component {
    constructor() {
        super()
        this.state = {
            loginPageDisplayed: 'login',
            email: '',
            password: '',
            demoMessage: '',
            systemMessage: '',
            resetMessage: '',
            demoError: '',
            firstName: '',
            lastName: '',
            emailConfirmation: '',
            demoHear: 'google',
            otherHear: '',
            loginError: '',
            displayCaptcha: false,
            captchaCompleted: false,
            authenticationError: false,
            loginErrorCount: 0,
        }
    }

    fetchForgotPassword = () => {
        fetch(`${this.props.route}/forgotpassword.webapi`, {
			method: 'POST',
			headers: {
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				email: this.state.email,
			})
		})
		.then(response => response.text())
		.then(result => {

			//Attempt sending logs
			fetchSubmitLogs(null, null)

			result = result.replace('{', '');
			result = result.replace('}', '');
			this.setState({resetMessage: result});
		})
		.catch(error => {
			if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
				this.setState({authenticationError: true})
			}
			console.log('error', error);
			logFetchError(error, null, null, 'fetchForgotPassword')
		});
    }

    fetchDemoSignup = (e) => {
        e.preventDefault()
		let hear;
		if (this.state.demoHear === 'other') {
			hear = this.state.otherHear;
		}
		else {
			hear = this.state.demoHear;
		}
		if (this.state.email !== this.state.emailConfirmation) {
			return this.setState({demoError: 'Emails do not match. Please check that you\'ve spelled everything correctly.'})
		}
		fetch(`${this.props.route}/demosignup.webapi`, {
			method: 'POST',
			headers: {
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerFirstName: this.state.firstName,
				customerLastName: this.state.lastName,
				customerEmail: this.state.email,
				whereDidYouHear: hear,
			})
		})
		.then(response => response.text())
		.then(result => {

			//Attempt sending logs
			fetchSubmitLogs(null, null)
			console.log(result)
			if (result.includes('signupError:')) {
				this.setState({demoError: result.slice(13, result.length)})
			} else {
				result = result.replace('{', '');
				result = result.replace('}', '');
				this.setState({demoMessage: result, loginPageDisplayed: 'login', demoError: ''});
			}
		})
		.catch(error => {
			console.log('error', error);
			logFetchError(error, null, null, 'fetchDemoSignup')
		});
    }

    fetchLogin = () => {
        if (!this.state.displayCaptcha || (this.state.displayCaptcha && this.state.captchaCompleted)) {

			fetch(`${this.props.route}/login.webapi`, {
				method: 'POST',
				headers: {
					'Content-Type': 'text/plain',
				},
				body: JSON.stringify({
					userName: this.state.email,
					password: this.state.password,
				})
			})
			.then(response => response.text())
			.then(result => {

				//Attempt sending logs
				fetchSubmitLogs(null, null)
				if (result.includes('Token')) {
                    this.props.updateUserData(JSON.parse(result))
				}
				else {
					result = result.replace('{', '');
					result = result.replace('}', '');
					this.setState({
						loginError: result,
						loginErrorCount: this.state.loginErrorCount + 1,
					});
					if (this.state.loginErrorCount === 5) {
						this.setState({displayCaptcha: true});
					}
					// TODO track number of failed logins on 5 captcha
				}
				this.setState({password: ''});
			})
			.catch(error => {
				console.log('error', error);
				logFetchError(error, null, null, 'fetchLoginToken')
			});
		}
		else {
			this.setState({loginError: 'You must complete the captcha'});
		}
    }

    render() {
        return (
            <div className='loginPageWrapper'>
                <div className='login-logo'><img className='logo' src={logo} alt='CcsCases'/><div className='login-color'></div></div>
				{!this.props.production &&
					<div className='testModeWarningContainer'>
						<p className='testModeWarningText'>Test Mode</p>
					</div>
				}
                <div className={`login-screen ${!this.props.production && 'login-screen-test-background'}`}>
                    {this.state.loginPageDisplayed === 'login' ?
                        <LoginPage
                            emailChange={(e) => this.setState({email: e.target.value})}
                            passwordChange={(e) => this.setState({password: e.target.value})}
                            loginClick={this.fetchLogin}
                            forgotPasswordClick={() => this.setState({loginPageDisplayed: 'forgotPassword'})}
                            demoClick={() => this.setState({loginPageDisplayed: 'demoSignup'})}
                            viewTermsOfService={() => this.setState({loginPageDisplayed: 'termsOfService'})}
                            demoMessage={this.state.demoMessage}
                            email={this.state.email}
                            password={this.state.password}
                            loginError={this.state.loginError}
                            captcha={this.state.displayCaptcha}
                            completeCaptcha={() => this.setState({captchaCompleted: true})}
                        />
                    : this.state.loginPageDisplayed === 'forgotPassword' ?
                        <ForgotPassword
                            closeForgotPassword={() => this.setState({loginPageDisplayed: 'login'})}
                            email={this.state.email}
                            emailChange={(e) => this.setState({email: e.target.value})}
                            resetMessage={this.state.resetMessage}
                            fetchForgotPassword={this.fetchForgotPassword}
                        />
                    : this.state.loginPageDisplayed === 'termsOfService' ?
                        <TermsOfService
                            closeTermsOfService={() => this.setState({loginPageDisplayed: 'login'})}
                        />
                    : this.state.loginPageDisplayed === 'demoSignup' &&
                        <DemoSignup
                            demoError={this.state.demoError}
                            firstName={this.state.firstName}
                            lastName={this.state.lastName}
                            email={this.state.email}
                            emailConfirmation={this.state.emailConfirmation}
                            demoHear={this.state.demoHear}
                            demoMessage={this.state.demoMessage}
                            fetchDemoSignup={this.fetchDemoSignup}
                            firstNameChange={(e) => this.setState({firstName: e.target.value})}
                            lastNameChange={(e) => this.setState({lastName: e.target.value})}
                            emailChange={(e) => this.setState({email: e.target.value})}
                            demoHearChange={(e) => this.setState({demoHear: e.target.value})}
                            updateEmailConfirmation={(e) => this.setState({emailConfirmation: e.target.value})}
                            closeDemoSignup={() => this.setState({loginPageDisplayed: 'login'})}
                            otherHear={this.state.otherHear}
                            otherHearChange={(e) => this.setState({otherHear: e.target.value})}
                        />
                    }
                </div>
				{this.state.authenticationError && <UnauthenticatedHandler />}
                {this.state.systemMessage !== '' && 
                    <div className='login-popup-message-container'>
                        <p className='login-popup-message'>{this.state.systemMessage}</p>
                        <button className='login-popup-close-button' onClick={() => this.setState({systemMessage: ''})}>X</button>
                    </div>
                }
            </div>
        )
    }
}
