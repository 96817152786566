import React, { useState, useEffect } from 'react'
import './CheckboxAnswer.css'

export default function CheckboxAnswer(props) {

    let [shuffled, setShuffled] = useState(false)
    let [answers, setAnswers] = useState([])

    const updateCheckboxAnswer = (e, value, index) => {
        e.stopPropagation()
        let newAnswers = [...answers]
        newAnswers[index].selected = !newAnswers[index].selected
        setAnswers(newAnswers)
        props.updateCheckboxAnswer(value.option)
    }

    const shuffleAnswers = (answerList) => {
        let tempAnswers = JSON.parse(JSON.stringify(answerList))
        var currentIndex = tempAnswers.length,  randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [tempAnswers[currentIndex], tempAnswers[randomIndex]] = [
            tempAnswers[randomIndex], tempAnswers[currentIndex]];
        }
        setAnswers(tempAnswers)
    }

    if (!shuffled) {
        shuffleAnswers(props.answerList[props.currentQuestion - 1])
        setShuffled(true)
    }

    useEffect(() => {
        shuffleAnswers(props.answerList[props.currentQuestion - 1])
    }, [props.currentQuestion])

    const getMultipleChoiceLetter = (index) => {
        if (index < 26) {
            return String.fromCharCode(65 + index)
        } else {
            let firstLetterIndex = Math.floor(index / 26) - 1
            return `${String.fromCharCode(65 + firstLetterIndex)}${String.fromCharCode(65 + (index % 26))}`
        }
    }

    return (
        <div>
            <p className='simulationBodyText'>{props.simulationData.qo[props.currentQuestion - 1].QUESTION}</p>
            <p className='simulationBodyText warningText'>You may select up to {props.simulationData.qo[props.currentQuestion - 1].MAXACCEPTEDANSWERS} of the {props.answerList[props.currentQuestion - 1].length} options listed below; 
            select the last option if no intervention is needed. You may need to use the scroll bar at the right 
            to view the entire list.
            </p>
            <p className='simulationBodyText highlightedHeader selectedCheckboxesAmount'>Number of options selected: {props.selectedCheckboxAmount}</p>
            <div className='checkboxAnswersContainer'>
                {answers.map((value, index) => (
                    <div className='checkboxAnswerWrapper' onClick={(e) => updateCheckboxAnswer(e, value, index)}>
                        <input type='checkbox' checked={value.selected} onChange={() => null} style={{cursor: 'pointer'}}/>
                        <p className='simulationBodyText checkboxOptionText'>{getMultipleChoiceLetter(index)}. {value.option}</p>
                    </div> 
                ))}
            </div>
            <p className='simulationBodyText highlightedHeader selectedCheckboxesAmount'>Number of options selected: {props.selectedCheckboxAmount}</p>
        </div>
    )
}
