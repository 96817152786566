import React from 'react';
import './Grading.css';
import HighYieldInformationPopup from './Components/HighYieldInformationPopup/HighYieldInformationPopup'
import FaqQuestionsArray from '../../FAQ_Json.json'
import logFetchError from '../../Functions/LogFetchError'
import fetchSubmitLogs from '../../Functions/FetchSubmitLogs'

class Grading extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			gradingView: 'feedback',
			enableFeedbackGrading: true,
			grading: this.props.grading,
			highYield: false,
			enableHighYieldSelect: true,
			displayHighYieldInformation: false,
			faqQuestions: [],
			selectedCase: null,
			caseList: null,
			reviewLater: props.reviewLater
		}
	}

	componentDidMount() {
		this.fetchViewCaseGrade()
		this.updateFaqQuestionsHandler()
	}

	fetchReviewLater = () => {
		let caseId;
		if (this.props.caseGrades) {
			caseId = this.props.caseGrades;
		}
		else {
			caseId = this.props.caseData.ID;
		}
		this.props.setFetchOutstanding(true)
		fetch(`${this.props.route}/reviewlater.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.props.userData.CustomerId,
				caseId: caseId,
				marked: !this.state.reviewLater,
			})
		})
		.then(response => {
			this.props.setFetchOutstanding(false)
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {
			this.reviewLaterToggle();
		})
		.catch(error => {
			this.props.setFetchOutstanding(false)
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchReviewLater')
		});
	}

	reviewLaterToggle = () => {
		this.setState({reviewLater: !this.state.reviewLater}, () => {
            this.props.updateReviewLater()
        });
	}

	updateFaqQuestionsHandler = () => {
		let questions = []
		let answers = []
		FaqQuestionsArray.forEach((value, index) => {
			let beginning = value.slice(0, 10)
			if (beginning.includes('question:')) {
				let question = value.slice(-value.length + 10)
				questions.push(question)
			} else if (beginning.includes('answer:')) {
				let answer = value.slice(-value.length + 8)
				answers.push(answer)
			}
		})
		let FAQ = []
		questions.forEach((value, index) => {
			FAQ.push({
				question: value,
				answer: answers[index]
			})
		})
		this.setState({faqQuestions: FAQ})
	}

	enableFeedbackGrading = () => {
		this.setState({enableFeedbackGrading: !this.state.enableFeedbackGrading});
	}

	setGradingView = (view) => {
		this.setState({gradingView: view});
	}

	markHighYieldHandler = () => {
		if (this.state.enableHighYieldSelect) {
			let caseId;
			if (this.props.caseGrades) {
				caseId = this.props.caseGrades;
			}
			else {
				caseId = this.props.caseData.ID;
			}
			this.setState({highYield: !this.state.highYield, enableHighYieldSelect: false}, () => {
				this.props.setFetchOutstanding(true)
				fetch(`${this.props.route}/highyield.webapi`, {
					method: 'POST',
					headers: {
						'Token': this.props.userData.Token,
						'Content-Type': 'text/plain',
					},
					body: JSON.stringify({
						customerid: this.props.userData.CustomerId,
						caseid: caseId,
						markhighyield: this.state.highYield,
						unmarkhighyield: !this.state.highYield
					})
				})
				.then(response => {
					this.props.setFetchOutstanding(false)
					//Attempt sending logs
					fetchSubmitLogs(this.props.userProfile, this.props.userData)

					if (response.status === 401) {
						this.props.setAuthenticationError()
						throw 'Authentication Error'
					} else {
						return response.text()
					}
				})
				.then(response => {
					this.setState({enableHighYieldSelect: true})
				})
				.catch((error) => {
					this.props.setFetchOutstanding(false)
					this.setState({highYield: !this.state.highYield, enableHighYieldSelect: true})
					logFetchError(error, this.props.userProfile, this.props.userData, 'markHighYieldHandler')
				})
			})
		}
	}

	fetchRetrieveCaseGrade = (id) => {
		this.props.setFetchOutstanding(true)
		fetch(`${this.props.route}/retreivecasegrade.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerGradeId: id,
			})
		})
		.then(response => {
			this.props.setFetchOutstanding(false)
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			let data = response
			data = JSON.parse(data)
			console.log(data)
			// console.log(data.pgolist.pgolist[2].GradeOut[0].OrderName)
			this.setState({
				selectedCase: id,
				grading: data,
				highYield: data.HighYieldFlag
			});
		})
		.catch(error => {
			this.props.setFetchOutstanding(false)
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log('error', error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchRetrieveCaseGrade')
		});
	}
	
	fetchViewCaseGrade = () => {
		let caseId;
		if (this.props.caseGrades) {
			caseId = this.props.caseGrades;
		}
		else {
			caseId = this.props.caseData.ID;
		}

		this.props.setFetchOutstanding(true)
		fetch(`${this.props.route}/viewcasegrade.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.props.userData.CustomerId,
				caseId: caseId,
			})
		})
		.then(response => {
			this.props.setFetchOutstanding(false)
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {

			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			let data = response
			// reverse order of array
			let array = JSON.parse(data).vcgo;
			array.reverse();
			let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
			console.log(array)
			let caseArray = [];// only display the cases that are of the case selected
			// format dates
			for (let i = 0; i < array.length; i++) {
				let date = new Date(`${array[i].CaseCompletionDate}Z`);

				let minutes = date.getUTCMinutes();
				if (minutes.toString().length === 1) {
					minutes = '0' + minutes;
				}

				array[i].CaseCompletionDate = months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear() + ', ' + date.getUTCHours() + ':' + minutes;

				if (array[i].CaseId === caseId && array[i].CaseCompletionDate !== 'January 1, 1, 0:00') {
					caseArray.push(array[i]);
				}
			}
			// if !this.props.grading fetch data for most recent case
			if (!this.props.grading) {
				this.fetchRetrieveCaseGrade(caseArray[0].CustomerGradeId);
			}
			let newCaseArray = []
			if (this.props.userProfile.selectedTimeZone !== "-7") {
				const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
				let hoursAdjustment = Number(this.props.userProfile.selectedTimeZone) + 7
				caseArray.forEach((value, index) => {
					let date = new Date(value.CaseCompletionDate)
					date.setTime(date.getTime() + (hoursAdjustment*60*60*1000))
					let newCaseArrayValue = value
					let dateString = `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`
					newCaseArrayValue.CaseCompletionDate = dateString
					newCaseArray.push(newCaseArrayValue)
				})
			} else {
				newCaseArray = [...caseArray]
			}
			this.setState({
				caseList: newCaseArray,
				selectedCase: caseArray[0].CustomerGradeId,
			});
		})
		.catch(error => {
			this.props.setFetchOutstanding(false)
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchViewCaseGrade')
		});
	}

	getMultipleChoiceOption = (optionIndex, questionId) => {
		let answer
		this.state.grading.ao.forEach((answerValue, answerIndex) => {
			if (answerValue.ANSWERID === optionIndex) {
				answer = answerValue.ANSWER
			}
		})
		return answer
	}

	render() {
        return(
            <div className='grading'>
				{this.state.displayHighYieldInformation && <HighYieldInformationPopup closePopup={() => this.setState({displayHighYieldInformation: false})}/>}
				<div className='grading-case-list'>
					<div className='grading-case-list-header'>{'Case Completion Date'}</div>
					<div className='grading-case-list-content'>
						{this.state.caseList?.map((data, index) => (
							<div className={'grading-case-list-date ' + (this.state.selectedCase === data.CustomerGradeId)} onClick={() => this.fetchRetrieveCaseGrade(data.CustomerGradeId)}>{data.CaseCompletionDate}</div>
						))}
					</div>
				</div>
				<div className='grading-view'>
					<div className='grading-view-tabs'>
						<div className='grading-view-tabs-wrapper'>
							<div className={'grading-view-tab ' + (this.state.gradingView === 'feedback')} onClick={() => this.setGradingView('feedback')}>{'Feedback'}</div>
							<div className={'grading-view-tab ' + (this.state.gradingView === 'Faq')} onClick={() => this.setGradingView('Faq')}>{'FAQ'}</div>
						</div>
						{/* {this.state.grading.NUMFIRSTATTEMPT >= 100 && <div className={'grading-view-text'}>Average first attempt score: {this.state.grading.AverageFirstAttempt.toFixed(2)}%</div>} */}
					</div>
                    {this.state.gradingView === 'feedback' ?
					    <div>
                            <div className='grading-feedback-header'>
                                {/* Score */}
                                <div>{'Overall Score: ' + (this.state.grading?.pgolist.Finalgrade ? this.state.grading?.pgolist.Finalgrade.toFixed(2) : 0) + '%'}</div>
                                <div>{`Final Diagnosis for this case: ${this.props.selectedCaseDiagnosis}`}</div>
                            </div>
							{this.state.grading?.pgolist.pgolist.map((pgoValue, pgoIndex) => (
								<div className='grading-feedback-category' key={`gradingCategory${pgoIndex}`}>
									<div className='grading-feedback-category-header'>
										Question {pgoIndex + 1} ({pgoValue.grade ? `${pgoValue.grade.toFixed(2)}%` : '0%'}) - {this.state.grading.qo[pgoIndex].QUESTION}
									</div>
										<div className={`grading-feedback-event userEntry`}>
											<div className='grading-feedback-event-header'>Your answer</div>
											<ul>
												{pgoValue.PreviousInput[0].CustomerAnswersBlank ? pgoValue.PreviousInput[0].CustomerAnswersBlank.map((inputValue, inputIndex) => (
													<li key={`Question${pgoIndex}Answer${inputIndex}`}>{inputValue === "" ? <b>Option left blank</b> : inputValue}</li>
												))
												: pgoValue.PreviousInput[0].CustomerAnswerMC.map((inputValue, inputIndex) => (
													<li key={`Question${pgoIndex}Answer${inputIndex}`}>{this.getMultipleChoiceOption(inputValue, pgoValue.QuestionId)}</li>
												))}
											</ul>
										</div>
										{pgoValue.GradeOut.map((gradedValue, gradedIndex) => (
											<div key={`GradeResult${pgoIndex}-${gradedIndex}`} className={`grading-feedback-event ${gradedValue.GradeColor === 'Correct - Green' ? 'correct' : gradedValue.GradeColor === 'Neutral - Blue' ? 'optional' : 'incorrect'}`}>
												<div className='grading-feedback-event-header'>{gradedValue.EventName}</div>
												<ul>
													{gradedValue.OrderName?.split('\\n').map((value) => (
														<li>{value}</li>
													))}
												</ul>
												<div><span className='grading-feedback-event-reason'>{'Reason: '}</span>{gradedValue.Explanation}</div>
											</div>
										))}
								</div>
							))}
							<div className='grading-feedback-category'>
								<div className='grading-feedback-category-header'>
									Case Summary
								</div>
								<div className={`grading-feedback-event userEntry`}>
									<p className='grading-case-summary' style={{margin: 0}}>{this.state.grading?.CaseSummary}</p>
								</div>
							</div>
                        </div>
					:
                        <div style={{height: 'calc(100% - 69px)', overflowY: 'auto'}}>
                            <div className='grading-feedback-header'>
                                <div>Frequently Asked Questions</div>
                            </div>
                            {this.state.faqQuestions.map((value, index) => (
                                <div className='grading-feedback-event optional' key={`gradingEvent${index}`}>
                                    <div className='grading-feedback-event-header' style={{fontWeight: 'bold'}}>{value.question}</div>
                                    <div>{value.answer}</div>
                                </div>
                            ))}
                        </div>
					}
					<div className='grading-view-footer'>
						{/* <div onClick={this.enableFeedbackGrading}>
							<input type='checkbox' checked={this.state.enableFeedbackGrading}/>
							<label>{'Enable Feedback Grading'}</label>
						</div> */}
						<div onClick={() => this.setState({displayHighYieldInformation: true})}>
							<input type='checkbox' checked={this.state.highYield} onClick={(event) => {event.stopPropagation(); this.markHighYieldHandler()}}/>
							<label><a onClick={(event) => {event.stopPropagation(); this.markHighYieldHandler()}}>High</a> Yield<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" style={{width: '14px', position: 'relative', top: '-2px', left: '2px'}} className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg></label>
						</div>
						<div onClick={() => this.fetchReviewLater()}>
							<input type='checkbox' checked={this.state.reviewLater}/>
							<label>{'Review Later'}</label>
						</div>
						<input type='button' value='Close Grading' onClick={this.props.closeGrading}/>
					</div>
				</div>
			</div>
        )
	}
}

export default Grading;
