import React, { Component } from 'react'
import './BarGraphModule.css'
import BarGraphElement from './BarGraphElement/BarGraphElement'

export default class BarGraphModule extends Component {
    constructor() {
        super()
        this.state = {
            personalAverage: 0,
            allUserAverage: 0,
        }
    }

    componentDidMount() {
        //Initialize toggled section values to display all
        this.props.jsonreport.forEach((section, sectionIndex) => {
            this.setState({[section.visibilityToggle]: true}, () => {
                //Wait until state is updated and we've set all variables, then calculate selected category averages
                if (sectionIndex === this.props.jsonreport.length - 1) {
                    this.getSelectedCategoryAverages()
                }
            })
        })
    }

    getSelectedCategoryAverages = () => {
        if (this.props.showAverages) {
            let totalCaseArray = []
            let personalAverage = 0
            let allUserAverage = 0
    
            //Loop through jsonreport and push active sections to totalCaseArray
            this.props.jsonreport.forEach((section, sectionIndex) => {
                if (this.state[section.visibilityToggle]) {
                    totalCaseArray.push(...this.props.jsonreport[sectionIndex].scores)
                }
            })
    
            //Loop through total case array and add each value to personal and all user averages
            totalCaseArray.forEach((value, index) => {
                personalAverage += Number(value.FirstCaseAttemptGrade)
                allUserAverage += Number(value.AverageGeneralUserFirstCaseAttempt)
            })
    
            //If the selected sections contain completed cases, calculate the average
            if (totalCaseArray.length > 0) {
                personalAverage = personalAverage / totalCaseArray.length
                allUserAverage = allUserAverage / totalCaseArray.length
            }
    
            this.setState({personalAverage, allUserAverage})
        }
    }

    toggleCategory = (toggleCategory) => {
        //Update the toggled category and then recalculate the averages of selected categories
        this.setState({[toggleCategory]: !this.state[toggleCategory]}, this.getSelectedCategoryAverages)
    }

    render() {
        return (
            <div className='reportCardModuleContainer' style={this.props.customContainerStyle}>
                <div className='reportCardModuleHeaderContainer'>
                    <h2 className='reportCardModuleHeaderText'>{this.props.title}</h2>
                </div>
                <div className='reportCardModuleBodyContainer'>
                    <div className='reportCardFirstCaseAttemptCheckboxWrapper'>
                        {/* Map the categories in json report into togglable checkboxes */}
                        {this.props.jsonreport.map((section, sectionIndex) => (
                            section.scores.length > 0 &&
                            <div key={`${this.props.title}-checkbox-${sectionIndex}`} className='reportCardCheckboxWrapper' onClick={() => this.toggleCategory(section.visibilityToggle)}>
                                <button id={section.title} className={`reportCardCheckbox ${this.state[section.visibilityToggle] ? 'checkedCheckbox' : ''}`}/>
                                <label htmlFor={section.title} className='reportCardCheckboxLabel'>{section.title}</label>
                            </div>
                        ))}
                    </div>
                    <div className='reportCardFirstCaseAttemptGraphWrapper'>
                        <div className='reportCardLegendContainer'>
                            <div className='reportCardGraphLegendWrapper'>
                                <div className='reportCardGraphLegend' style={{backgroundColor: '#D1ECF1'}}/>
                                <p className='reportCardGraphLegendText'>{this.props.legendPrimary}</p>
                            </div>
                            <div className='reportCardGraphLegendWrapper'>
                                <div className='reportCardGraphLegend' style={{backgroundColor: '#EEEEEE'}}/>
                                <p className='reportCardGraphLegendText'>{this.props.legendSecondary}</p>
                            </div>
                        </div>
                        <div className='reportCardFirstCaseAttemptGraphContainer'>
                            {this.props.jsonreport.map((section, sectionIndex) => (
                                section.scores.length > 0 && 
                                <div key={`${this.props.title}-${sectionIndex}`} className={`reportCardFirstCaseAttemptGraphSectionWrapper ${this.state[section.visibilityToggle] ? '' : 'hideBarGraphContainer'}`}>
                                    <p className='reportCardFirstCaseAttemptGraphSectionTitle'>{section.title}</p>
                                    <div className='reportCardFirstCaseAttemptGraphElementWrapper'>
                                        {section.scores.map((caseData, caseIndex) => (
                                            <BarGraphElement
                                                key={`${this.props.title}-${sectionIndex}-${caseIndex}`}
                                                primary={Math.round(Number(caseData[this.props.primaryValueKey]))}
                                                secondary={Math.round(Number(caseData[this.props.secondaryValueKey]))}
                                                caseId={caseData.CaseId}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {this.props.showAverages &&
                        <div className='reportCardFirstCaseAttemptTitleValueWrapper'>
                            <div className='reportCardTitleValueContainer'>
                                <p className='reportCardTitleValueText'>Overall Average For Selected Categories</p>
                                <p className='reportCardTitleValueText'>{this.state.personalAverage.toFixed(1)}%</p>
                            </div>
                            <div className='reportCardTitleValueContainer'>
                                <p className='reportCardTitleValueText'>All Users’ Average For Selected Categories</p>
                                <p className='reportCardTitleValueText'>{this.state.allUserAverage.toFixed(1)}%</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}