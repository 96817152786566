import React, { Component } from 'react'
import './App.css';
import CaseList from './Components/CaseList/CaseList';
import Login from './Components/Login/Login';
import Simulation from './Components/Simulation/Simulation';
import logFetchError from './Functions/LogFetchError'
import fetchSubmitLogs from './Functions/FetchSubmitLogs'
import Spinner from 'react-md-spinner'
import Grading from './Components/Grading/Grading'
import UnauthenticatedHandler from './Components/UnauthenticatedHandler/UnauthenticatedHandler'
import NewCaseList from './NewCaseList/NewCaseList'
import AccountSettings from './AccountSettings/AccountSettings'
import ReportCard from './ReportCard/ReportCard'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import StaticJsonCaseList from './static_cases_info.json'
import ConfirmationPopup from './Util/ConfirmationPopup/ConfirmationPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import LoginPage from './NewLogin/LoginPage'
import NewCaseSelectorPopup from './NewCaseSelectorPopup/NewCaseSelectorPopup'
import NewGrading from './NewGrading/NewGrading'
import NewUnauthenticatedPopup from './Util/NewUnauthenticatedPopup/NewUnauthenticatedPopup'
import { faStethoscope, faBrain, faTruckMedical, faChild, faVenus, faBone } from '@fortawesome/free-solid-svg-icons'
import { faHeadSideBrain } from '@fortawesome/pro-solid-svg-icons'

const THEMES = [
	{
		themeTitle: 'CDM Cases Classic',
		themeDescription: 'Our personal favorite - A modest design with high contrast accents',
		primaryColor: '#0B335D',
		secondaryColor: '#F79D23',
		primaryBackgroundColor: '#e7e7e7',
		secondaryBackgroundColor: '#ffffff',
		tertiaryBackgroundColor: '#3E3E3E',
		offBackgroundColor: '#cecece',
		textColor: '#1d1d1d',
		caseTextColor: '#1d1d1d',
		secondaryTextColor: '#ffffff',
		offTextColor: '#686868',
		secondaryOffTextColor: '#cecece',
		tertiaryOffTextColor: '#1d1d1d',
		caseSelectedColor: '#e5ebf3',
	},
	{
		themeTitle: 'CDM After Dark',
		themeDescription: 'A dark mode variant of our classic theme for the night owls out there.',
		primaryColor: '#3670ae',
		secondaryColor: '#F79D23',
		primaryBackgroundColor: '#252525',
		secondaryBackgroundColor: '#3c3c3c',
		tertiaryBackgroundColor: '#4a4a4a',
		offBackgroundColor: '#cecece',
		textColor: '#ffffff',
		caseTextColor: '#ffffff',
		secondaryTextColor: '#ffffff',
		offTextColor: '#a4a4a4',
		secondaryOffTextColor: '#cecece',
		tertiaryOffTextColor: '#ffffff',
		caseSelectedColor: '#454b53',
	},
	{
		themeTitle: 'CDM Staycation',
		themeDescription: 'Even doctors need a break. We thought we\'d bring the beach to you - You\'re welcome.',
		primaryColor: '#266595',
		secondaryColor: '#0e4976',
		primaryBackgroundColor: '#8b7555',
		secondaryBackgroundColor: '#ead6b6',
		tertiaryBackgroundColor: '#caac81',
		offBackgroundColor: '#ccb188',
		textColor: '#3e2708',
		caseTextColor: '#3e2708',
		secondaryTextColor: '#fff8ef',
		offTextColor: '#8a6f46',
		secondaryOffTextColor: '#5b3705',
		tertiaryOffTextColor: '#f5ddbc',
		caseSelectedColor: '#e5ebf3',
	},
	{
		themeTitle: 'CDM Jungle',
		themeDescription: 'We don\'t have a very good explanation for this one.',
		primaryColor: '#4c6c4c',
		secondaryColor: '#a4ad27',
		primaryBackgroundColor: '#543e2f',
		secondaryBackgroundColor: '#a4886b',
		tertiaryBackgroundColor: '#293928',
		offBackgroundColor: '#cecece',
		textColor: '#dfc3a2',
		caseTextColor: '#f5ddc0',
		secondaryTextColor: '#f9f0e7',
		offTextColor: '#ddcab3',
		secondaryOffTextColor: '#ddb68d',
		tertiaryOffTextColor: '#dfc3a2',
		caseSelectedColor: '#a88055',
	}
]

class App extends Component {
	constructor() {
		super()
		this.state = {
			authenticationError: false,
			userData: {},
			userProfile: {},
			timedExam: true,
			customTimeLimit: 'none',
			networkLag: 'none',
			caseGrades: null,
			caseListHeaderError: '',
			caseListScrollPosition: 0,
			route: 'https://app.cdmcases.com',
			fetchOutstanding: false,
			production: true,
			loginError: '',
			applicationView: 'login',
			showLoading: false,
			startCaseData: {},
			caseData: {},
			simulationData: {},
			selectedCaseId: 0,
			practiceMode: false,
			grading: null,
			selectedCaseReviewLater: null,
			caseListSort: 'id',
			caseListReversed: false,
			showCaseTitle: true,
			showCaseDiagnosis: false,
			gradingMode: 'complete',
			unfilteredCaseList: [],
			loadingCaseList: false,
			hiddenCases: [],
			primaryColor: '#0B335D',
			hideCasePopup: false,
			selectedTheme: "0",
			updateDisplayedCasesCallback: null,
			loadingCaseList: false,
			displayConfirmationPopup: false,
			confirmationPopupOptions: null,
			popupLoading: false,
			selectedCase: -1,
			themeChangeIndex: -1,
			initialSettingsPage: null,
			caseListScrollPosition: 0,
			continueCaseLoading: false,
			showNewCaseSelectorPopup: false,
			newCaseSelectorPopupConfirmLoading: false,
			newCaseSelectorPopupCancelLoading: false,
			gradingMode: 'complete',
			pageHeight: window.innerHeight,
			enableAnswerPeeking: false,
			windowsSystem: this.isUserOnWindows(),
			printReceiptHTML: null,
			categories: [
                {
                    icon: faStethoscope,
                    name: "Internal Medicine",
                    checked: true
                },
                {
                    icon: faBrain,
                    name: "Neurology",
                    checked: true
                },
                {
                    icon: faVenus,
                    name: "OB/GYN",
                    checked: true
                },
                {
                    icon: faChild,
                    name: "Pediatrics",
                    checked: true
                },
                {
                    icon: faTruckMedical,
                    name: "Emergency Medicine",
                    checked: true
                },
                {
                    icon: faHeadSideBrain,
                    name: "Psychiatry",
                    checked: true
                },
				{
					icon: faBone,
					name: "Osteopathic",
					checked: true
				}
            ],
            hideCompleted: false,
            hideIncomplete: false,
			sortOrder: 'Id',
			sortDirection: 'Ascending'
		}
	}

	componentDidMount() {
		this.getRoute()
	}

	logout = () => {
		window.location.reload();
	}

	isUserOnWindows = () => {
		return navigator.userAgent.indexOf('Windows') > -1;
	}

	changeTheme = async (theme, saveChange = true) => {

		if (!theme && theme !== 0)
			return

		this.setState({selectedTheme: theme, primaryColor: THEMES[theme].primaryColor})

		document.documentElement.style.setProperty('--new-primary-color', THEMES[theme].primaryColor)
		document.documentElement.style.setProperty('--new-secondary-color', THEMES[theme].secondaryColor)
		document.documentElement.style.setProperty('--new-primary-background-color', THEMES[theme].primaryBackgroundColor)
		document.documentElement.style.setProperty('--new-secondary-background-color', THEMES[theme].secondaryBackgroundColor)
		document.documentElement.style.setProperty('--new-tertiary-background-color', THEMES[theme].tertiaryBackgroundColor)
		document.documentElement.style.setProperty('--new-off-background-color', THEMES[theme].offBackgroundColor)
		document.documentElement.style.setProperty('--new-text-color', THEMES[theme].textColor)
		document.documentElement.style.setProperty('--new-case-text-color', THEMES[theme].caseTextColor)
		document.documentElement.style.setProperty('--new-secondary-text-color', THEMES[theme].secondaryTextColor)
		document.documentElement.style.setProperty('--new-off-text-color', THEMES[theme].offTextColor)
		document.documentElement.style.setProperty('--new-secondary-off-text-color', THEMES[theme].secondaryOffTextColor)
		document.documentElement.style.setProperty('--new-tertiary-off-text-color', THEMES[theme].tertiaryOffTextColor)
		document.documentElement.style.setProperty('--case-selected-color', THEMES[theme].caseSelectedColor)
		document.querySelector("meta[name=theme-color]").setAttribute("content", THEMES[theme].tertiaryBackgroundColor)

		if (saveChange) {
			this.setState({themeChangeIndex: theme})
			const response = await fetch(`${this.state.route}/changetheme.webapi`, {
				method: 'POST',
				headers: {
					'Token': this.state.userData.Token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					Theme: theme,
					CustomerId: this.state.userData.CustomerId
				})
			})
			this.setState({themeChangeIndex: -1})

			if (response.status === 401) {
				return this.setState({authenticationError: true})
			}

			if (response.ok) {
				const apiMessage = await response.text()
				console.log(apiMessage)
			} else {
				console.log('error', response)
			}
		}
	}

	getRoute = () => {
		let route = ''
		if (process.env.REACT_APP_PRODUCTION !== 'false') {
			route = 'https://app.cdmcases.com'
			this.setState({production: true})
		} else {
			route = `http://localhost:${process.env.REACT_APP_NETWORK_PORT ? process.env.REACT_APP_NETWORK_PORT : 80}`
			this.setState({production: false})
		}
		this.setState({route})
	}

  	formatTimeHandler = (date) => {
		let formattedDate = new Date(date)
		let hours = formattedDate.getUTCHours()
		let minutes = formattedDate.getUTCMinutes()
		let timeString = `${(hours === 0 || hours === 12) ? '12' : hours > 12 ? hours - 12 : hours}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`
		return timeString
	}

  	formatDateHandler = (date) => {
		let formattedDate = new Date(date)
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		let dateString = `${months[formattedDate.getUTCMonth()]} ${formattedDate.getUTCDate()}, ${formattedDate.getUTCFullYear()}, ${this.formatTimeHandler(date)}`
		return dateString
	}
  
	// updateAccountDetails = (user) => {
	// 	let userProfile = {
	// 		firstName: user.CustomerFirstName,
	// 		lastName: user.CustomerLastName,
	// 		userName: user.CustomerUserName,
	// 		subscriptionEndDate: this.formatDateHandler(`${user.LicenseExpiration}Z`),
	// 		accountCreationDate: this.formatDateHandler(`${user.DateCustCreated}Z`),
	// 		demo: user.Demo,
	// 		caseCompletionLimit: user.LimitFiveCases,
	// 		selectedTimeZone: user.TimeZone === "" ? "-7" : user.TimeZone
	// 	}
	// 	//Current default offset is -6
	// 	this.setState({userProfile, showLoading: user.LoadingPopUp ? true : false})
	// }


	updateAccountDetails = (user) => {
		// console.log(user)
		// console.log(JSON.parse(JSON.stringify(user)))
		let userProfile = {
			firstName: user.CustomerFirstName,
			lastName: user.CustomerLastName,
			userName: user.CustomerUserName,
			subscriptionEndDate: this.formatDateHandler(`${user.LicenseExpiration}Z`),
			accountCreationDate: this.formatDateHandler(`${user.DateCustCreated}Z`),
			demo: user.Demo,
			caseCompletionLimit: user.LimitFiveCases,
			selectedTimeZone: user.TimeZone === "" ? "-7" : user.TimeZone,
			hideHighYield: user.TurnOffHighYield,
			animationsDisabled: user.AnimationsDisabled,
			DontShowHideCasePopup: user.DontShowHideCasePopup,
			// SeenCaseSelectorPopup: user.SeenCaseSelectorPopup,
			SeenCaseSelectorPopup: false,
			NewCaseSelector: user.NewCaseSelector,
			DisableFeedback: user.DisableFeedback,
			ModernList: user.ModernList,
			ClassicGrading: user.ClassicGrading,
		}
		let expirationDate = new Date(user.LicenseExpiration)
		let todaysDate = new Date()
		let dateDiff = new Date(expirationDate - todaysDate)
		let daysDiff = dateDiff.getTime() / 1000 / 60 / 60 / 24

		let showNewCaseSelectorPopup
		if (process.env.REACT_APP_FORCE_CASE_SELECTOR_POPUP !== 'false') {
			showNewCaseSelectorPopup = true
		} else {
			showNewCaseSelectorPopup = userProfile.SeenCaseSelectorPopup ? false : true
		}

		this.setState({
			userProfile,
			showLoading: user.LoadingPopUp ? true : false,
			showSubscriptionEndWarning: daysDiff < 7 ? true : false,
			subscriptionDaysRemaining: Math.floor(daysDiff),
			showNewCaseSelectorPopup: showNewCaseSelectorPopup,
		})
	}

	updateSelectedTimeZone = (value) => {
		let initialTimeZone = this.state.userProfile.selectedTimeZone
		let userProfile = this.state.userProfile
		userProfile.selectedTimeZone = value
		let userData = this.state.userData
		userData.TimeZone = value
		this.setState({userProfile, userData})
		fetch(`${this.state.route}/updatetimezone.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				timeZone: value
			})
		})
		.then((response) => {
		})
		.catch((error) => {
			let userProfile = this.state.userProfile
			userProfile.selectedTimeZone = initialTimeZone
			this.setState({userProfile})
			logFetchError(error, this.state.userProfile, this.state.userData, 'updateSelectedTimeZone')
		})
	}

  	showLoadingHandler = () => {
		let newValue = !this.state.showLoading
		this.setState({fetchOutstanding: true, showLoading: newValue})
		fetch(`${this.state.route}/loadingpopup.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				LoadingPopUp: newValue
			})
		})
		.then((response) => {
			this.setState({fetchOutstanding: false})
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			}
		})
		.catch((error) => {
			this.setState({fetchOutstanding: false, showLoading: !newValue})
			logFetchError(error, this.state.userProfile, this.state.userData, 'showLoadingHandler')
		})
  	}

  	getAnswerList = (simulationData) => {
		let answerList = []
		simulationData.qo.forEach((value, index) => {
			if (value.BLANKTYPE) {
				let inputAnswerInfo = []
				for(let i = 0; i < value.MAXACCEPTEDANSWERS; ++i) {
					inputAnswerInfo.push('')
				}
				answerList.push(inputAnswerInfo)
			} else if (value.MCTYPE) {
				let multipleChoiceAnswerInfo = []
				simulationData.ao.forEach((answerOutput, answerIndex) => {
					if (answerOutput.QUESTIONID === value.QUESTIONID) {
						multipleChoiceAnswerInfo.push({
							option: answerOutput.ANSWER,
							selected: false
						})
					}
				})
				answerList.push(multipleChoiceAnswerInfo)
			}
		})
		return answerList
  	}
  
  	fetchStartCase = async (caseData, openIncompleteCaseWindow) => {
	  	this.setState({selectedCaseId: caseData.ID, fetchOutstanding: true, selectedCaseReviewLater: caseData.ReviewLaterFlag})
		fetch(`${this.state.route}/startcase.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.state.userData.CustomerId,
				caseId: caseData.ID
			})
		})
		.then(response => {
			this.setState({fetchOutstanding: false})
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {

			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

			let result = response
			if (result.includes('Error: ')) {
				this.setState({caseListHeaderError: result})
			} else {
				let simulationData = JSON.parse(result);
				this.setState({caseguid: simulationData.caseguid})

				simulationData.timeRemainingInSeconds = simulationData.TOTALMINFORCASE * 60

				simulationData.INITIALINFO = simulationData.INITIALINFO.split(',')

				let newInfo = []

				// Find degrees text and replace it with character, then split string into two seperate values and add them
				// to newInfo as an object
				simulationData.INITIALINFO.forEach((value, index) => {
					let tempValue = value
					if (tempValue.includes(' degrees ')) {
						tempValue = tempValue.replace(' degrees ', '°')
					}
					let tempArray = tempValue.split(': ')
					newInfo.push({
						vital: tempArray[0],
						value: tempArray[1]
					})
				})
				simulationData.INITIALINFO = [...newInfo]

				simulationData.answerList = this.getAnswerList(simulationData)

				simulationData.currentQuestion = 1
				if (simulationData.ialist.length === 0) {
					this.setState({
						caseData: caseData,
						caseListHeaderError: '',
						simulationData: simulationData,
						applicationView: 'simulation'
					}, () => {
						this.setState({})
					})
				} else {
          			let incompleteCaseList = [...simulationData.ialist]
					this.setState({
						caseData: caseData,
						caseListHeaderError: '',
						simulationData: simulationData
					})
					openIncompleteCaseWindow(incompleteCaseList.reverse(), () => {
						console.log('called callback')
						this.setState({applicationView: 'simulation'}, () => {
							console.log(this.state)
						})
					})
				}
			}
		})
		.catch(error => {
			this.setState({fetchOutstanding: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.setState({authenticationError: true})
			// }
			console.log('error', error);
			logFetchError(error, this.state.userProfile, this.state.userData, 'fetchStartCase')
		});
	}

	confirmIncompleteCaseStart = (selectedIncompleteCase, incompleteCaseList) => {
		let incompleteStartData = {}
		this.setState({fetchOutstanding: true})
		fetch(`${this.state.route}/incompletestart.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				caseguid: this.state.caseguid,
				CurrentStateId: incompleteCaseList[selectedIncompleteCase].CurrentStateId
			})
		})
		.then(response => {
			this.setState({fetchOutstanding: false})
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then((response) => {
			incompleteStartData = JSON.parse(response)
			// let currentProgress = JSON.parse(incompleteStartData.CurrentProgress)
			let previousInput

			if (incompleteStartData.PreviousInput !== "") {
				previousInput = JSON.parse(incompleteStartData.PreviousInput)
			} else {
				previousInput = []
			}

			let temp = {...this.state.simulationData}
			let timedExam = this.state.timedExam

			if (previousInput.length > 0) {
				timedExam = previousInput[0].isTimed
			}


			previousInput.forEach((inputValue, inputIndex) => {
				if (inputValue.CustomerAnswersBlank !== null) {
					temp.answerList[inputIndex] = [...inputValue.CustomerAnswersBlank]
				} else {
					inputValue.CustomerAnswerMC.forEach((value, index) => {
						//Get question index that corresponds with question id in answerList and replace value - 1
						let answerIndex = temp.ao.findIndex((answerOutputValue) => {
							if (answerOutputValue.ANSWERID === value) {
								return true
							}
						})
						temp.answerList[inputIndex][answerIndex].selected = true
					})
				}
			})

			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

			temp.currentQuestion = previousInput ? previousInput.length + 1 : 1
			temp.timeRemainingInSeconds = incompleteStartData.TimeLeft

			console.log(temp)

			this.setState({
				simulationData: temp,
				applicationView: 'simulation',
				fetchOutstanding: false,
				timedExam
			})
		})
		.catch((error) => {
			this.setState({fetchOutstanding: false})
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'confirmIncompleteCaseStart')
		})
	}

	viewCaseGrades = (id, reviewLater) => {
		this.setState({
			grading: null,
			applicationView: 'grading',
			caseGrades: id,
			simulationData: null,
			reviewLater: reviewLater,
			selectedCase: id,
			selectedCaseId: id
		});
	}

	viewCompletedExamGrades = (id, reviewLater) => {
		this.fetchCaseList()
		this.viewCaseGrades(id, reviewLater)
	}

	fetchReviewLater(caseData) {
		this.setState({fetchOutstanding: true})
		fetch(`${this.state.route}/reviewlater.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.state.userData.CustomerId,
				caseId: caseData.id,
				marked: caseData.reviewLater
			})
		})
		.then(response => {
			this.setState({fetchOutstanding: false})
			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)

			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw 'Authentication Error'
			} else {
				return response.text()
			}
		})
		.then(response => {
			// this.props.reviewLaterToggle(this.props.data.ID);
		})
		.catch(error => {
			this.setState({fetchOutstanding: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'fetchReviewLater')
		});
	}

	exitSimulationHandler = () => {
		this.setState({applicationView: 'caseList', caseGrades: this.state.caseData.ID})
	}

	setGradingModeAndUpdateCases = (newMode) => {
		this.setState({gradingMode: newMode}, () => {
			this.fetchCaseList()
		})
	}

	fetchCaseList = async () => {
        this.setState({loadingCaseList: true})
		// await fetch(`${this.state.route}/listcases.webapi`, {
		await fetch(`${this.state.route}/listcasesupdate.webapi`, {
			method: 'GET',
			headers: {
				'Token': this.state.userData.Token,
			},
		})
		.then(response => {
			if (response.status === 401) {
                this.setState({authenticationError: true})
				throw new Error('Authentication Error')
			} else {
                return response.text()
			}
		})
		.then(response => {
			//Attempt sending logs
			fetchSubmitLogs(this.state.userProfile, this.state.userData)
            let results = JSON.parse(response)

            // Merge each result with its corresponding id in the static json
            let caseList = []
            for (let i = 0; i < results.length; i++) {
                let userCaseData = results[i]
				// find StaticJsonCaseList.lc[i].ID that matches userCaseData.ID
				let staticCaseData = StaticJsonCaseList.lc.find((staticCase) => {
					return staticCase.ID === userCaseData.ID
				})
                // let staticCaseData = StaticJsonCaseList.lc[i]
                let newCaseObject = {...userCaseData, ...staticCaseData}
                caseList.push(newCaseObject)
            }

			results = {...this.state.userData}
			results.lc = caseList

			let hiddenCases = []
			if (this.state.userData.HiddenCases.length > 0) {
				hiddenCases = JSON.parse(this.state.userData.HiddenCases)
				hiddenCases = hiddenCases.map(hiddenCase => parseInt(hiddenCase))
			}

            let hiddenCasesAndCategories = []
            for (let i = 0; i < hiddenCases.length; i++) {
                let hiddenCase = hiddenCases[i]
                hiddenCasesAndCategories.push({
                    id: hiddenCase,
                    category: this.getCaseCategoryFromId(hiddenCases[i], results.lc)
                })
            }

			console.log(results)
            
            this.updateHiddenCases(hiddenCasesAndCategories)
            this.updateAccountDetails(results)
            this.setState({unfilteredCaseList: results.lc, loadingCaseList: false}, () => {
				if (this.state.updateDisplayedCasesCallback) {
                	this.state.updateDisplayedCasesCallback()
				}
            })
		})
		.catch(error => {
            this.setState({loadingCaseList: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log('error', error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchCaseList')
		});
    }

	updateHiddenCases = (hiddenCases) => {
		this.setState({hiddenCases})
	}

	reviewLaterToggle = (id) => {
		let tempJson = this.state.unfilteredCaseList;
		for (let i = 0; i < tempJson.length; i++) {
			if (tempJson[i].ID === id) {
				tempJson[i].ReviewLaterFlag = !tempJson[i].ReviewLaterFlag;
			}
		}
		this.setState({unfilteredCaseList: tempJson}, () => {
			if (this.state.updateDisplayedCasesCallback !== null) {
				this.state.updateDisplayedCasesCallback()
			}
        });
	}

	getCaseCategoryFromId = (caseId, caseList = this.state.unfilteredCaseList) => {
        for (let i = 0; i < caseList.length; i++) {
            if (caseList[i].ID === caseId) {
                return caseList[i].CASECATEGORYDESC
            }
        }
    }

	setDisplayedCasesCallback = (callback) => {
		this.setState({updateDisplayedCasesCallback: callback})
	}

	handleLogin = (userData) => {
		// console.log(userData)
		let state = {...this.state, ...userData}
		state.applicationView = 'caseList'
		this.changeTheme(Number(state.userData.Theme), false)
		this.setState(state, () => {
			this.fetchCaseList(true)
		})
	}
	
	toggleCaseListStyle = async () => {
		let userProfile = {...this.state.userProfile}
		userProfile.ModernList = !userProfile.ModernList

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			ModernList: userProfile.ModernList
		})
		// console.log(body)

		await fetch(`${this.state.route}/togglemodernlist.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then(async (response) => {
			this.setState({userProfile})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.setState({authenticationError: true})
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
			// console.log(response)
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'toggleCaseListStyle')
		})
	}

	disableAnimationsHandler = async () => {
		let userProfile = {...this.state.userProfile}
		userProfile.animationsDisabled = !userProfile.animationsDisabled

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			animationsDisabled: userProfile.animationsDisabled
		})

		await fetch(`${this.state.route}/toggleanimationsdisable.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			this.setState({userProfile})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.setState({authenticationError: true})
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
			console.log(response)
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'disableAnimationsHandler')
		})
	}

	setSelectedCase = (caseId, callback = null) => {
		this.setState({selectedCase: caseId}, () => {
			if (callback !== null) {
				callback()
			}
		})
	}

	unhideCase = async (caseId) => {
		let hiddenCases = this.state.hiddenCases
		let index = hiddenCases.findIndex((hiddenCase) => {
			return hiddenCase.id === caseId
		})

		if (index <= -1) {
			return
		}

		let body = JSON.stringify({
			CaseNumberToHide: caseId,
			CustomerId: this.state.userData.CustomerId,
			RemoveCase: true
		})

		await fetch(`${this.state.route}/addremovehiddencases.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			hiddenCases.splice(index, 1)
			this.setState({hiddenCases})
		})
		.catch((error) => {
			logFetchError(error, this.state.userProfile, this.state.userData, 'unhideCase')
		})
	}

	displayConfirmationPopup = (confirmationPopupOptions) => {
		this.setState({displayConfirmationPopup: true, confirmationPopupOptions})
	}

	printReceipt = () => {
		window.print()
	}

	toggleSortDirection = (updateCaseList = true) => {
		if (this.state.sortDirection === 'Ascending') {
			this.setState({sortDirection: 'Descending'}, () => {
				if (this.state.updateDisplayedCasesCallback !== null && updateCaseList) {
					this.state.updateDisplayedCasesCallback()
				}
			})
		} else {
			this.setState({sortDirection: 'Ascending'}, () => {
				if (this.state.updateDisplayedCasesCallback !== null && updateCaseList) {
					this.state.updateDisplayedCasesCallback()
				}
			})
		}
	}

	hideCasePopupCheckboxHandler = () => {
        let confirmationPopupOptions = {...this.state.confirmationPopupOptions}
        confirmationPopupOptions.children = (
            <div className='confirmationPopupCheckboxWrapper' onClick={this.hideCasePopupCheckboxHandler}>
                <div className='checkboxContainer' key={this.state.hideCasePopup}>
                    {this.state.hideCasePopup && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                </div>
                <p className='confirmationPopupCheckboxLabel'>Don't show this again</p>
            </div>
        )
		this.setState({hideCasePopup: !this.state.hideCasePopup})
        this.setState({confirmationPopupOptions})
    }

	seenCaseSelectorHandler = async (userDecision) => {
		this.setState({
			newCaseSelectorPopupConfirmLoading: userDecision ? true : false,
			newCaseSelectorPopupCancelLoading: userDecision ? false : true
		})
		let userProfile = {...this.state.userProfile}
		userProfile.SeenCaseSelectorPopup = true
		await fetch(`${this.state.route}/toggleseencaseselectorpopup.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				SeenCaseSelectorPopup: true
			})
		})
		.then((response) => {
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then((response) => {
			console.log(response)
			this.setState({userProfile}, async () => {
				await this.toggleNewCaseSelector(userDecision)
				this.setState({
					showNewCaseSelectorPopup: false,
					newCaseSelectorPopupConfirmLoading: false,
					newCaseSelectorPopupCancelLoading: false,
				})
			})
		})
		.catch((error) => {
			console.log(error)
			this.setState({
				newCaseSelectorPopupConfirmLoading: false,
				newCaseSelectorPopupCancelLoading: false
			})
			logFetchError(error, this.state.userProfile, this.state.userData, 'seenCaseSelectorHandler')
		})
	}

	toggleNewCaseSelector = async (newCaseSelector = !this.state.userProfile.NewCaseSelector) => {
		let userProfile = {...this.state.userProfile}
		userProfile.NewCaseSelector = newCaseSelector
		let userData = {...this.state.userData}
		userData.NewCaseSelector = newCaseSelector

		let caseSelectorFetch = await fetch(`${this.state.route}/togglenewcaseselector.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				NewCaseSelector: newCaseSelector,
				CustomerId: this.state.userData.CustomerId
			})
		})

		if (caseSelectorFetch.ok) {
			let caseSelectorResponse = await caseSelectorFetch.text()
			console.log(caseSelectorResponse)
			this.setState({userProfile, userData})
		} else if (caseSelectorFetch.status === 401) {
			this.setState({authenticationError: true})
		} else {
			console.log('Error: ' + caseSelectorFetch.status)
		}
	}

	toggleClassicGrading = async () => {
		let userProfile = {...this.state.userProfile}
		userProfile.ClassicGrading = !userProfile.ClassicGrading
		let userData = {...this.state.userData}
		userData.ClassicGrading = userProfile.ClassicGrading

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			ClassicGrading: userProfile.ClassicGrading
		})

		await fetch(`${this.state.route}/toggleclassicgrading.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			this.setState({userProfile, userData})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.setState({authenticationError: true})
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
			console.log(response)
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'toggleClassicGrading')
		})
	}

	closeGrading = () => {
		this.setState({
			applicationView: 'caseList',
			grading: null,
			feedbackMessage: ''
		});
	}

	updateReviewLater = () => {
        let caseIndex = this.state.unfilteredCaseList.findIndex(caseData => caseData.ID === this.state.selectedCase)
		let unfilteredCaseList = [...this.state.unfilteredCaseList]
		unfilteredCaseList[caseIndex].ReviewLaterFlag = !unfilteredCaseList[caseIndex].ReviewLaterFlag

		this.setState({unfilteredCaseList})
	}

	unhideAllCasesHandler = async () => {
		let hiddenCases = this.state.hiddenCases
		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			ResetHiddenCases: true
		})

		await fetch(`${this.state.route}/resethiddencases.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			hiddenCases = []
			this.setState({hiddenCases})
		})
		.catch((error) => {
			console.log(error)
			logFetchError(error, this.state.userProfile, this.state.userData, 'unhideAllCasesHandler')
		})
	}

	updateCategories = (categories, callback) => {
		this.setState({categories}, callback)
	}

	toggleHideCompleted = () => {
        if (this.state.hideIncomplete && !this.state.hideCompleted) {
            this.setState({hideIncomplete: false, hideCompleted: true}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
					this.state.updateDisplayedCasesCallback(false)
				}
            })
        } else {
            this.setState({hideCompleted: !this.state.hideCompleted}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
					this.state.updateDisplayedCasesCallback(false)
				}
            })
        }
    }

    toggleHideIncomplete = () => {
        if (this.state.hideCompleted && !this.state.hideIncomplete) {
            this.setState({hideCompleted: false, hideIncomplete: true}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
					this.state.updateDisplayedCasesCallback(false)
				}
            })
        } else {
            this.setState({hideIncomplete: !this.state.hideIncomplete}, () => {
				if (this.state.updateDisplayedCasesCallback !== null) {
					this.state.updateDisplayedCasesCallback(false)
				}
            })
        }
    }

	// toggleDisableFeedback = async () => {
	// 	let userProfile = {...this.state.userProfile}
	// 	userProfile.DisableFeedback = !userProfile.DisableFeedback

	// 	let body = JSON.stringify({
	// 		CustomerId: this.state.userData.CustomerId,
	// 		DisableFeedback: userProfile.DisableFeedback
	// 	})

	// 	await fetch(`${this.state.route}/toggledisablefeedback.webapi`, {
	// 		method: 'POST',
	// 		headers: {
	// 			'Token': this.state.userData.Token,
	// 			'Content-Type': 'text/plain',
	// 		},
	// 		body: body
	// 	})
	// 	.then((response) => {
	// 		this.setState({userProfile})
	// 		if (response.ok) {
	// 			return response.text()
	// 		} else if (response.status === 401) {
	// 			return this.setState({authenticationError: true})
	// 		} else {
	// 			throw new Error('Error')
	// 		}
	// 	})
	// 	.then((response) => {
	// 		console.log(response)
	// 	})
	// 	.catch((error) => {
	// 		console.log(error)
	// 		logFetchError(error, this.state.userProfile, this.state.userData, 'disableAnimationsHandler')
	// 	})
	// }

	timedExamToggle = () => {
		this.setState({timedExam: !this.state.timedExam});
	}

	customTimeLimitChange = (event) => {
		this.setState({customTimeLimit: event.target.value});
	}

	viewHiddenCases = () => {
		this.setState({applicationView: 'manageHiddenCases'})
	}

	getSelectedCaseDiagnosis = () => {
		if (this.state.userData.Demo) {
			let selectedCaseIndex = this.state.unfilteredCaseList.findIndex(caseData => caseData.ID === this.state.selectedCaseId)
			return this.state.unfilteredCaseList[selectedCaseIndex].DIAGNOSIS
		} else {
			return this.state.unfilteredCaseList[this.state.selectedCaseId - 1].DIAGNOSIS
		}
	}

	hideHighYieldHandler = async () => {
		let userProfile = {...this.state.userProfile}
		let newValue = !userProfile.hideHighYield
		userProfile.hideHighYield = newValue
		this.setState({userProfile})
		await fetch(`${this.state.route}/turnoffhighyield.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				TurnOffHighYield: newValue
			})
		})
		.then(() => {

		})
		.catch((error) => {
			userProfile.hideHighYield = !newValue
			this.setState({userProfile})
			logFetchError(error, this.state.userProfile, this.state.userData, 'hideHighYieldHandler')
		})
	}

	render() {
		if (this.state.printReceiptHTML !== null) {
			return (
				<div className='printReceiptContainer'>
					<div dangerouslySetInnerHTML={{__html: this.state.printReceiptHTML}} />
					<div className='printReceiptButtonContainer'>
						<button className='printReceiptButton secondaryButton' onClick={() => this.setState({printReceiptHTML: null})}>Cancel</button>
						<button className='printReceiptButton primaryButton' onClick={this.printReceipt}>Print</button>
					</div>
				</div>
			)
		}

		// return <NewGrading />

		let applicationView;
		let loginLogo;

		// switch between the main application views
		if (this.state.applicationView === 'caseList') {
			if (this.state.userData) {
				if (!this.state.userProfile.NewCaseSelector) {
					applicationView = (
						<CaseList 
							updateAccountDetails={this.updateAccountDetails}
							setAuthenticationError={() => this.setState({authenticationError: true})}
							userData={this.state.userData}
							userProfile={this.state.userProfile}
							fetchStartCase={this.fetchStartCase}
							timedExam={this.state.timedExam}
							timedExamToggle={this.timedExamToggle}
							customTimeLimit={this.state.customTimeLimit}
							customTimeLimitChange={this.customTimeLimitChange}
							viewCaseGrades={this.viewCaseGrades}
							networkLag={this.state.networkLag}
							networkLagChange={this.networkLagChange}
							accountSettingsClick={this.accountSettingsClick}
							caseListHeaderError={this.state.caseListHeaderError}
							confirmIncompleteCaseStart={this.confirmIncompleteCaseStart}
							route={this.state.route}
							setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
							viewReportCard={() => this.setState({applicationView: 'reportCard'})}
							caseListSort={this.state.caseListSort}
							changeCaseListSort={(newSort) => this.setState({caseListSort: newSort})}
							showCaseTitle={this.state.showCaseTitle}
							toggleShowCaseTitle={() => this.setState({showCaseTitle: !this.state.showCaseTitle})}
							practiceMode={this.state.practiceMode}
							togglePracticeMode={() => this.setState({practiceMode: !this.state.practiceMode})}
							showCaseDiagnosis={this.state.showCaseDiagnosis}
							toggleShowCaseDiagnosis={() => this.setState({showCaseDiagnosis: !this.state.showCaseDiagnosis})}
							showSubscriptionEndWarning={this.state.showSubscriptionEndWarning}
							subscriptionDaysRemaining={this.state.subscriptionDaysRemaining}
							unfilteredCaseList={this.state.unfilteredCaseList}
							setSelectedCase={this.setSelectedCase}
							selectedCase={this.state.selectedCase}
							setCurrentScrollPosition={(scrollPosition) => this.setState({caseListScrollPosition: scrollPosition})}
							scrollPosition={this.state.caseListScrollPosition}
							primaryColor={this.state.primaryColor}
							changePage={(newPage) => this.setState({applicationView: newPage})}
						/>
					)
				} else {
					applicationView = (
						<NewCaseList
							changePage={(newPage) => this.setState({applicationView: newPage})}
							updateAccountDetails={this.updateAccountDetails}
							userData={this.state.userData}
							userProfile={this.state.userProfile}
							setAuthenticationError={() => this.setState({authenticationError: true})}
							route={this.state.route}
							viewCaseGrades={this.viewCaseGrades}
							peekCaseGrades={this.peekCaseGrades}
							gradingMode={this.state.gradingMode}
							setGradingMode={this.setGradingModeAndUpdateCases}
							fetchStartCase={this.fetchStartCase}
							confirmIncompleteCaseStart={this.confirmIncompleteCaseStart}
							updateHiddenCases={this.updateHiddenCases}
							hiddenCases={this.state.hiddenCases}
							primaryColor={this.state.primaryColor}
							secondaryColor={THEMES[this.state.selectedTheme].secondaryColor}
							showCaseTitle={this.state.showCaseTitle}
							toggleShowCaseTitle={() => this.setState({showCaseTitle: !this.state.showCaseTitle})}
							showCaseDiagnosis={this.state.showCaseDiagnosis}
							toggleShowCaseDiagnosis={() => this.setState({showCaseDiagnosis: !this.state.showCaseDiagnosis})}
							hideCasePopup={this.state.hideCasePopup}
							toggleHideCasePopup={(newValue) => this.setState({hideCasePopup: newValue})}
							unfilteredCaseList={this.state.unfilteredCaseList}
							loadingCaseList={this.state.loadingCaseList}
							reviewLaterToggle={this.reviewLaterToggle}
							getCaseCategoryFromId={this.getCaseCategoryFromId}
							setDisplayedCasesCallback={this.setDisplayedCasesCallback}
							StaticJsonCaseList={StaticJsonCaseList}
							displayConfirmationPopup={this.displayConfirmationPopup}
							closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
							setPopupLoading={(newValue) => this.setState({popupLoading: newValue})}
							hideCasePopupCheckboxHandler={this.hideCasePopupCheckboxHandler}
							setSelectedCase={this.setSelectedCase}
							selectedCase={this.state.selectedCase}
							animationsDisabled={this.state.userProfile.animationsDisabled}
							viewHiddenCases={this.viewHiddenCases}
							continueCaseLoading={this.state.continueCaseLoading}
							loadingPopupStartCase={this.state.loadingPopupStartCase}
							key="CaseList"
							timedExam={this.state.timedExam}
							timedExamToggle={this.timedExamToggle}
							customTimeLimit={this.state.customTimeLimit}
							customTimeLimitChange={this.customTimeLimitChange}
							networkLag={this.state.networkLag}
							networkLagChange={this.networkLagChange}
							selectedTheme={THEMES[this.state.selectedTheme]}
							enableAnswerPeeking={this.state.enableAnswerPeeking}
							windowsSystem={this.state.windowsSystem}
							categories={this.state.categories}
							updateCategories={this.updateCategories}
							hideCompleted={this.state.hideCompleted}
							toggleHideCompleted={this.toggleHideCompleted}
							hideIncomplete={this.state.hideIncomplete}
							toggleHideIncomplete={this.toggleHideIncomplete}
							sortOrder={this.state.sortOrder}
							updateSortOrder={(newSortOrder, callback) => this.setState({sortOrder: newSortOrder}, callback)}
							caseListStyle={this.state.userProfile.ModernList}
							toggleCaseListStyle={this.toggleCaseListStyle}
							showSubscriptionEndWarning={this.state.showSubscriptionEndWarning}
							subscriptionDaysRemaining={this.state.subscriptionDaysRemaining}
							sortDirection={this.state.sortDirection}
							toggleSortDirection={this.toggleSortDirection}
							practiceMode={this.state.practiceMode}
							togglePracticeMode={() => this.setState({practiceMode: !this.state.practiceMode})}
						/>
					)
				}
			}
		}
		else if (this.state.applicationView === 'simulation') {
			applicationView = <Simulation
				simulationData={this.state.simulationData}
				setAuthenticationError={() => this.setState({authenticationError: true})}
				route={this.state.route}
				userData={this.state.userData}
				caseData={this.state.caseData}
				selectedCaseId={this.state.selectedCaseId}
				timedExam={this.state.timedExam}
				practiceMode={this.state.practiceMode}
				exitSimulation={this.exitSimulationHandler}
				fetchOutstanding={this.state.fetchOutstanding}
				setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
				displayGrading={() => this.viewCompletedExamGrades(this.state.caseData.ID, this.state.selectedCaseReviewLater)}
				caseguid={this.state.caseguid}
				userProfile={this.state.userProfile}
			/>
		}
		else if (this.state.applicationView === 'grading') {
			if (this.state.userProfile.ClassicGrading) {
				applicationView = <Grading
					grading={this.state.grading}// this is the json grading data if after case
					setAuthenticationError={() => this.setState({authenticationError: true})}
					userData={this.state.userData}
					userProfile={this.state.userProfile}
					caseData={this.state.caseData}
					selectedCase={this.state.selectedCase}
					closeGrading={this.closeGrading}
					caseGrades={this.state.caseGrades}
					reviewLater={this.state.reviewLater}
					route={this.state.route}
					setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
					primaryColor={this.state.primaryColor}
					gradingMode={this.state.gradingMode}
					EnableFeedback={!this.state.userProfile.DisableFeedback}
					animationsDisabled={this.state.userProfile.animationsDisabled}
					updateReviewLater={this.updateReviewLater}
					selectedCaseDiagnosis={this.getSelectedCaseDiagnosis()}
				/>
			} else {
				applicationView = <NewGrading
					grading={this.state.grading}
					setAuthenticationError={() => this.setState({authenticationError: true})}
					animationsDisabled={this.state.userProfile.animationsDisabled}
					caseGrades={this.state.caseGrades}
					userData={this.state.userData}
					userProfile={this.state.userProfile}
					caseData={this.state.caseData}
					selectedCase={this.state.selectedCase}
					closeGrading={this.closeGrading}
					reviewLater={this.state.reviewLater}
					route={this.state.route}
					setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
					primaryColor={this.state.primaryColor}
					gradingMode={this.state.gradingMode}
					unfilteredCaseList={this.state.unfilteredCaseList}
					selectedTheme={THEMES[this.state.selectedTheme]}
					themeNumber={this.state.selectedTheme}
					DisableFeedback={this.state.userProfile.DisableFeedback}
					displayConfirmationPopup={this.displayConfirmationPopup}
					closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
					updateReviewLater={this.updateReviewLater}
					selectedCaseDiagnosis={this.getSelectedCaseDiagnosis()}
				/>
			}
		}
		else if (this.state.applicationView === 'login') {

			applicationView = (
				<LoginPage 
					handleLogin={this.handleLogin}
					route={this.state.route}
					primaryColor={this.state.primaryColor}
					selectedTheme={THEMES[this.state.selectedTheme]}
				/>
			)
		}
		else if (this.state.applicationView === 'accountSettings' || this.state.applicationView === 'manageHiddenCases') {
			applicationView = (
				<AccountSettings
					changePage={(newPage) => this.setState({applicationView: newPage})}
					applicationView={this.state.applicationView}
					logout={this.logout}
					userData={this.state.userData}
					userProfile={this.state.userProfile}
					updateSelectedTimeZone={this.updateSelectedTimeZone}
					updateHiddenCases={this.updateHiddenCases}
					updateShowLoading={this.updateShowLoading}
					showLoadingHandler={this.showLoadingHandler}
					hideHighYieldHandler={this.hideHighYieldHandler}
					hiddenCases={this.state.hiddenCases}
					selectedTimeZone={this.state.selectedTimeZone}
					showLoading={this.state.showLoading}
					hideHighYield={this.state.userProfile.hideHighYield}
					unhideCase={this.unhideCase}
					primaryColor={this.state.primaryColor}
					setAuthenticationError={() => this.setState({authenticationError: true})}
					route={this.state.route}
					key="AccountSettings"
					toggleNewCaseSelector={this.toggleNewCaseSelector}
					showNewCaseSelector={this.state.userProfile.NewCaseSelector}
					hideCasePopup={this.state.hideCasePopup}
					toggleHideCasePopup={() => this.setState({hideCasePopup: !this.state.hideCasePopup})}
					unhideAllCasesHandler={this.unhideAllCasesHandler}
					displayConfirmationPopup={this.displayConfirmationPopup}
					closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
					setPopupLoading={(newValue) => this.setState({popupLoading: newValue})}
					changeTheme={this.changeTheme}
					selectedTheme={this.state.selectedTheme}
					themes={THEMES}
					themeStyle={THEMES[this.state.selectedTheme]}
					themeChangeIndex={this.state.themeChangeIndex}
					disableAnimationsHandler={this.disableAnimationsHandler}
					DisableFeedback={this.state.userProfile.DisableFeedback}
					// toggleDisableFeedback={this.toggleDisableFeedback}
					initialPage={this.state.initialSettingsPage}
					enableAnswerPeeking={this.state.enableAnswerPeeking}
					toggleAnswerPeeking={() => this.setState({enableAnswerPeeking: !this.state.enableAnswerPeeking})}
					setPrintReceiptHTML={(newValue) => this.setState({printReceiptHTML: newValue})}
					toggleClassicGrading={this.toggleClassicGrading}
				/>
			);
		}
		else if (this.state.applicationView === 'changePassword') {
			applicationView = (
				<div className={`login-screen ${!this.state.production && 'login-screen-test-background'}`}>
					<div className='login-box'>
						<div className='login-box-element'>
							<div>Current Password:</div>
							<input className='login-field' type='password' value={this.state.password} onChange={this.passwordChange}/>
						</div>
						<div className='login-box-element'>
							<div>New Password:</div>
							<input className='login-field' type='password' value={this.state.newPassword} onChange={this.newPasswordChange}/>
						</div>
						<div className='login-box-element'>
							<div>Confirm Password:</div>
							<input className='login-field' type='password' value={this.state.confirmPassword} onChange={this.confirmPasswordChange}/>
						</div>
						<div className='login-message'>{this.state.changePasswordMessage}</div>
						<div className='login-box-element'>
							<input className='login-button' type='button' value='Submit' onClick={this.fetchChangePassword}/>
						</div>
						<div className='login-box-element'>
							<input className='login-button' type='button' value='Cancel' onClick={() => this.setState({applicationView: 'accountSettings'})}/>
						</div>
					</div>
				</div>
			);
		}
		else if (this.state.applicationView === 'submitFeedback') {
			applicationView = (
				<div className={`login-screen ${!this.state.production && 'login-screen-test-background'}`}>
					<div className='login-box'>
						<div className='login-box-element'>
							<div>Describe your issue</div>
							<textarea className='feedbackTextarea' type='text' value={this.state.feedbackContent} onChange={(e) => this.setState({feedbackContent: e.target.value})}/>
						</div>
						<div className='login-box-element'>
							<input className='login-button' type='button' value='Submit' onClick={this.submitFeedbackHandler}/>
						</div>
						<div className='login-box-element'>
							<input className='login-button' type='button' value='Cancel' onClick={() => this.setState({applicationView: 'accountSettings', feedbackErrorMessage: ''})}/>
						</div>
						<div className='login-message'>{this.state.feedbackErrorMessage}</div>
					</div>
				</div>
			);
		}
		else if (this.state.applicationView === 'reportCard') {
			applicationView = <ReportCard
				userData={this.state.userData}
				userProfile={this.state.userProfile}
				route={this.state.route}
				returnToCaseList={() => this.setState({applicationView: 'caseList'})}
				setAuthenticationError={() => this.setState({authenticationError: true})}
			/>
		}

		return (
			<div className='app' style={{height: this.state.pageHeight}}>
				{loginLogo}
				{!this.state.production &&
					<div className='testModeWarningContainer'>
						<p className='testModeWarningText'>Test Mode</p>
					</div>
				}
				<AnimatePresence exitBeforeEnter>
					{applicationView}
				</AnimatePresence>
				<AnimatePresence exitBeforeEnter>
					{this.state.authenticationError &&
						<NewUnauthenticatedPopup
							animationsDisabled={this.state.userProfile.animationsDisabled}
						/>
					}
				</AnimatePresence>
				{(this.state.showLoading && this.state.fetchOutstanding) &&
					<div className='loadingPopupWrapper'>
						<div className='loadingPopupBackdrop' />
						<div className='loadingPopupContainer'>
							<Spinner size={40} singleColor={'#1c4670'}/>
							<p className='loadingPopupText'>Loading...</p>
						</div>
					</div>
				}
				{/* <AnimatePresence>
					{this.state.showNewCaseSelectorPopup &&
						<NewCaseSelectorPopup
							seenCaseSelectorHandler={this.seenCaseSelectorHandler}
							key={"NewCaseSelectorPopup"}
							confirmLoading={this.state.newCaseSelectorPopupConfirmLoading}
							cancelLoading={this.state.newCaseSelectorPopupCancelLoading}
							primaryColor={this.state.primaryColor}
						/>
					}
				</AnimatePresence> */}
				<AnimatePresence>
					{this.state.displayConfirmationPopup &&
						<ConfirmationPopup
							options={this.state.confirmationPopupOptions}
							key={"ConfirmationPopup"}
							popupLoading={this.state.popupLoading}
							primaryColor={this.state.primaryColor}
							animationsDisabled={this.state.userProfile.animationsDisabled}
						>
							{this.state.confirmationPopupOptions.children}
						</ConfirmationPopup>
					}
				</AnimatePresence>
			</div>
		)
	}
}

export default App;
