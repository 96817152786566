import React, { Component } from 'react'
import AnswerPopup from './Components/AnswerPopup/AnswerPopup'
import Comments from './Components/Comments/Comments'
import ExitCasePopup from './Components/ExitCasePopup/ExitCasePopup'
import LabPanels from './Components/LabPanels/LabPanels'
import SimulationBody from './Components/SimulationBody/SimulationBody'
import SimulationFooter from './Components/SimulationFooter/SimulationFooter'
import SimulationHeader from './Components/SimulationHeader/SimulationHeader'
import './Simulation.css'
import logFetchError from '../../Functions/LogFetchError'
import HelpPopup from './Components/HelpPopup/HelpPopup'

export default class Simulation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCheckboxAmount: 0,
            displayCommentsPopup: false,
            comments: "",
            displayLabPanels: false,
            timeRemaining: props.simulationData.timeRemainingInSeconds,
            currentQuestion: props.simulationData.currentQuestion,
            submitStep: false,
            answerList: props.simulationData.answerList,
            displayAnswerPopup: false,
            displayExitCasePrompt: false,
            gradeOutput: [],
            caseEnded: false,
            showHelpPopup: false,
        }
    }

    timer = null

    componentDidMount() {
        if (this.timer === null  && this.props.timedExam) {
            this.startCaseTimer()
        }
    }

    startCaseTimer = () => {
        this.timer = setInterval(() => {
            let timeRemaining = this.state.timeRemaining
            if (timeRemaining - 1 === 0) {
                this.setState({caseEnded: true, timeRemaining: 0})
                clearInterval(this.timer)
                this.handleAnswerSubmission(this.props.simulationData.answerList.length)
            } else {
                timeRemaining--
                this.setState({timeRemaining})
            }
        }, 1000)
    }

    pauseCaseTimer = () => {
        clearInterval(this.timer)
    }

    updateCheckboxAnswer = (optionName) => {
        let index = 0;
        let currentQuestion = this.state.currentQuestion - 1
        let answerList = [...this.state.answerList]
        for(let i = 0; i < answerList[currentQuestion].length; ++i) {
            if (optionName === answerList[currentQuestion][i].option) {
                index = i;
                break;
            }
        }
        answerList[currentQuestion][index].selected = !answerList[currentQuestion][index].selected
        let selectedCheckboxAmount = this.state.selectedCheckboxAmount
        if (answerList[currentQuestion][index].selected) {
            selectedCheckboxAmount++
        } else {
            selectedCheckboxAmount--
        }
        console.log(answerList)
        this.setState({answerList, selectedCheckboxAmount})
    }

    modifyInputAnswer = (e, index) => {
        let answerInfo = [...this.state.answerList]
        if (e.target.value.length <= 100) {
            answerInfo[this.state.currentQuestion - 1][index] = e.target.value
            this.setState({answerInfo})
        }
    }

    formatAnswer = (currentQuestion) => {
        let formattedAnswerList = []
        this.state.answerList.forEach((value, index) => {
            if (index < currentQuestion) {
                let answer = {
                    customerId: this.props.userData.CustomerId,
                    caseId: this.props.selectedCaseId,
                    questionId: this.props.simulationData.qo[index].QUESTIONID,
                    caseguid: this.props.caseguid,
                    customerAnswersBlank: null,
                    customerAnswerMC: null,
                    timeLeftInSec: this.state.timeRemaining,
                    completeSubmission: (index + 1) === this.props.simulationData.answerList.length ? true : false,
                    isTimed: this.props.timedExam
                }
                if (typeof value[0] === 'string') {
                    answer.customerAnswersBlank = [...value]
                } else {
                    let multipleChoiceOptions = []
                    this.props.simulationData.ao.forEach((aoValue, aoIndex) => {
                        if (aoValue.QUESTIONID === answer.questionId) {
                            multipleChoiceOptions.push(aoValue)
                        }
                    })
                    answer.customerAnswerMC = []
                    value.forEach((value, index) => {
                        if (value.selected) {
                            answer.customerAnswerMC.push(multipleChoiceOptions[index].ANSWERID)
                        }
                    })
                }
                formattedAnswerList.push(answer)
            }
        })

        if (currentQuestion === this.props.simulationData.answerList.length) {
            return formattedAnswerList
        } else {
            return formattedAnswerList[formattedAnswerList.length - 1]
        }
    }

    handleAnswerSubmission = async (currentQuestion) => {
        if (currentQuestion === this.props.simulationData.answerList.length) {
            await this.fetchFinalQuestionSubmission(currentQuestion)
        } else {
            if (this.props.practiceMode) {
                await this.fetchPracticeModeSubmission(currentQuestion)
            } else {
                await this.fetchMoveOn(currentQuestion)
            }
        }
    }

    fetchPracticeModeSubmission = async (currentQuestion) => {
        this.props.setFetchOutstanding(true)
        let answer = this.formatAnswer(currentQuestion)
        await fetch(`${this.props.route}/moveon.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify(answer)
        })
        .then((res) => {
            if (res.ok) {
                return res.text()
            } else if (res.status === 401) {
                this.props.setAuthenticationError()
            } else {
                throw new Error('Unable to submit answers')
            }
        })
        .then(async () => {
            await fetch(`${this.props.route}/grading.webapi`, {
                method: 'POST',
                headers: {
                    'Token': this.props.userData.Token,
                    'Content-Type': 'text/plain',
                },
                body: JSON.stringify([answer])
            })
            .then((res) => {
                if (res.ok) {
                    return res.text()
                } else if (res.status === 401) {
                    this.props.setAuthenticationError()
                } else {
                    throw new Error('Unable to submit answers')
                }
            })
            .then((res) => {
                this.props.setFetchOutstanding(false)
                let result = JSON.parse(res)
                this.pauseCaseTimer()
                this.setState({
                    displayAnswerPopup: true,
                    gradeOutput: result.pgolist,
                    selectedCheckboxAmount: 0
                })
            })
        })
        .catch((error) => {
            this.props.setFetchOutstanding(false)
            console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchPracticeModeSubmission')
        })
    }

    fetchMoveOn = async (currentQuestion) => {
        this.props.setFetchOutstanding(true)
        let answer = this.formatAnswer(currentQuestion)
        await fetch(`${this.props.route}/moveon.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify(answer)
        })
        .then((res) => {
            if (res.ok) {
                return res.text()
            } else if (res.status === 401) {
                this.props.setAuthenticationError()
            } else {
                throw new Error('Unable to submit answers')
            }
        })
        .then(() => {
            this.props.setFetchOutstanding(false)
            this.setState({currentQuestion: currentQuestion + 1, submitStep: false, selectedCheckboxAmount: 0}, () => {
                let answerStart = document.getElementById('beginQuestionElement')
                let headerContainer = document.getElementById('headerContainer')
                window.scrollTo(0, answerStart.offsetTop - headerContainer.offsetHeight - 10)
            })
        })
        .catch((error) => {
            this.props.setFetchOutstanding(false)
            console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchMoveOn')
        })
    }

    fetchFinalQuestionSubmission = async (currentQuestion) => {
        this.props.setFetchOutstanding(true)
        let answers = this.formatAnswer(currentQuestion)

        await fetch(`${this.props.route}/grading.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify(answers)
        })
        .then((res) => {
            if (res.ok) {
                return res.text()
            } else if (res.status === 401) {
                this.props.setAuthenticationError()
            } else {
                throw new Error('Unable to submit answers')
            }
        })
        .then((res) => {
            this.props.setFetchOutstanding(false)
            let result = JSON.parse(res)
            this.props.displayGrading(result)
        })
        .catch((error) => {
            this.props.setFetchOutstanding(false)
            console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchFinalQuestionSubmission')
        })
    }

    continueCaseHandler = () => {
        if ((this.state.currentQuestion === this.props.simulationData.answerList.length) || this.state.caseEnded) {
            this.props.displayGrading()
        } else {
            this.startCaseTimer()
            this.setState({currentQuestion: this.state.currentQuestion + 1, submitStep: false, displayAnswerPopup: false}, () => {
                let answerStart = document.getElementById('beginQuestionElement')
                let headerContainer = document.getElementById('headerContainer')
                window.scrollTo(0, answerStart.offsetTop - headerContainer.offsetHeight - 10)
            })
        }
    }

    render() {
        return (
            <div className='simulationPageContainer'>
                <SimulationHeader
                    timedExam={this.props.timedExam}
                    timeRemaining={this.state.timeRemaining}
                    simulationData={this.props.simulationData}
                    currentQuestion={this.state.currentQuestion}
                    displayLabPanels={() => this.setState({displayLabPanels: true})}
                />
                <SimulationBody
                    updateCheckboxAnswer={this.updateCheckboxAnswer}
                    selectedCheckboxAmount={this.state.selectedCheckboxAmount}
                    simulationData={this.props.simulationData}
                    currentQuestion={this.state.currentQuestion}
                    modifyInputAnswer={this.modifyInputAnswer}
                    answerList={this.state.answerList}
                    caseNumber={this.props.caseData.ID}
                    showHelpPopup={() => this.setState({showHelpPopup: true})}
                />
                <SimulationFooter
                    showComments={() => this.setState({displayCommentsPopup: true})}
                    submitStep={this.state.submitStep}
                    updateSubmitStep={() => this.setState({submitStep: true})}
                    submitAnswer={() => this.handleAnswerSubmission(this.state.currentQuestion)}
                    displayExitCasePrompt={() => this.setState({displayExitCasePrompt: true})}
                    displayLabPanels={() => this.setState({displayLabPanels: true})}
                />
                <LabPanels
                    displayLabPanels={this.state.displayLabPanels}
                    closeLabPanels={() => this.setState({displayLabPanels: false})}
                />
                <Comments
                    displayCommentsPopup={this.state.displayCommentsPopup}
                    comments={this.state.comments}
                    updateComments={(event) => this.setState({comments: event.target.value})}
                    closeComments={() => this.setState({displayCommentsPopup: false})}
                />
                {this.state.displayAnswerPopup &&
                    <AnswerPopup
                        displayAnswerPopup={this.state.displayAnswerPopup}
                        continueCase={this.continueCaseHandler}
                        simulationData={this.props.simulationData}
                        currentQuestion={this.state.currentQuestion}
                        gradeOutput={this.state.gradeOutput}
                        caseEnded={this.state.caseEnded}
                    />
                }
                <ExitCasePopup
                    displayExitCasePrompt={this.state.displayExitCasePrompt}
                    confirmExitCase={this.props.exitSimulation}
                    cancelExitCase={() => this.setState({displayExitCasePrompt: false})}
                />
                {this.state.showHelpPopup &&
                    <HelpPopup
                        closeHelpPopup={() => this.setState({showHelpPopup: false})}
                    />
                }
            </div>
        )
    }
}
