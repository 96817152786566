import React from 'react'

export default function ForgotPassword(props) {
    return (
        <div className='login-box'>
            <div className='login-box-element'>
                <div>Email:</div>
                <input className='login-field' type='email' value={props.email} onChange={props.emailChange}/>
            </div>
            <div className='login-message'>{props.resetMessage}</div>
            <div className='login-box-element bottom' style={{marginBottom: '5px'}}>
                <input className='login-button' type='button' value='Reset Password' onClick={props.fetchForgotPassword}/>
            </div>
            <div className='login-box-element bottom'>
                <input className='login-button' type='button' value='Cancel' onClick={props.closeForgotPassword}/>
            </div>
        </div>
    )
}
