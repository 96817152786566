import React from 'react'

export default function DemoSignup(props) {
    return (
        <div>
            <form className='login-box' onSubmit={props.fetchDemoSignup}>
                {props.demoError !== '' &&
                    <p style={{margin: '0px', marginBottom: '10px', color:'red'}}>{props.demoError}</p>
                }
                <div className='login-box-element'>
                    <label>First Name:</label>
                    <input className='login-field' required type='text' value={props.firstName} onChange={props.firstNameChange}/>
                </div>
                <div className='login-box-element'>
                    <label>Last Name:</label>
                    <input className='login-field' required type='text' value={props.lastName} onChange={props.lastNameChange}/>
                </div>
                <div className='login-box-element'>
                    <label>Email:</label>
                    <input className='login-field' required type='email' value={props.email} onChange={props.emailChange}/>
                </div>
                <div className='login-box-element'>
                    <label>Confirm Email:</label>
                    <input className='login-field' required type='email' value={props.emailConfirmation} onChange={props.updateEmailConfirmation}/>
                </div>
                <div className='login-box-element'>
                    <label>Where did you hear about us?</label>
                    <select value={props.demoHear} onChange={props.demoHearChange}>
                        <option value='google'>Google Search</option>
                        <option value='bing'>Bing Search</option>
                        <option value='facebook'>Facebook</option>
                        <option value='forum'>Student Doctor Forum</option>
                        <option value='friend'>Friend</option>
                        <option value='other'>Other</option>
                    </select>
                    {props.demoHear === 'other' &&
                        <input className='login-field' type='text' value={props.otherHear} onChange={props.otherHearChange}/>
                    }
                </div>
                <div className='login-message'>{props.demoMessage}</div>
                <div className='login-box-element bottom' style={{marginBottom: '5px'}}>
                    <input className='login-button' type='submit' value='Join Demo'/>
                </div>
                <div className='login-box-element bottom'>
                    <input className='login-button' type='button' value='Cancel' onClick={props.closeDemoSignup}/>
                </div>
            </form>
        </div>
    )
}
