import React, { Component } from 'react'
import './PerformanceOverTimeModule.css'
import LineGraph from './LineGraph/LineGraph'

export default class PerformanceOverTimeModule extends Component {
    constructor() {
        super()
        this.state = {
            averageImprovementPerAttempt: 0.6,
            averageImprovementPerDay: 1.5
        }
    }

    componentDidMount() {
        this.getAverageImprovementPerAttempt()
        this.getAverageImprovementPerDay()
    }

    getAverageImprovementPerAttempt = () => {
        let startScore = Number(this.props.reportCardData.da[0].AverageDayGrade)
        let lastScore = Number(this.props.reportCardData.da[this.props.reportCardData.da.length - 1].AverageDayGrade)
        let scoreDifference = lastScore - startScore

        let totalCaseAttempts = Number(this.props.reportCardData.TotalNumberOfCasesAttempted)

        this.setState({averageImprovementPerAttempt: scoreDifference / totalCaseAttempts})
    }

    getAverageImprovementPerDay = () => {
        let startDay = Math.abs(this.props.reportCardData.da[0].DaysFromToday)
        let lastDay = Math.abs(this.props.reportCardData.da[this.props.reportCardData.da.length - 1].DaysFromToday)
        let dayTotal = startDay - lastDay

        let startScore = Number(this.props.reportCardData.da[0].AverageDayGrade)
        let lastScore = Number(this.props.reportCardData.da[this.props.reportCardData.da.length - 1].AverageDayGrade)
        let scoreDifference = lastScore - startScore

        this.setState({averageImprovementPerDay: scoreDifference / dayTotal})
    }

    render() {
        return (
            <div className='reportCardModuleContainer'>
                <div className='reportCardModuleHeaderContainer'>
                    <h2 className='reportCardModuleHeaderText'>Performance Over Time</h2>
                </div>
                <div className='performanceOverTimeBodyWrapper'>
                    <LineGraph
                        data={this.props.reportCardData.da}
                    />
                </div>
                <div className='performanceOverTimeTitleValueWrapper'>
                    <div className='reportCardTitleValueContainer'>
                        <p className='reportCardTitleValueText'>Average Improvement Per Attempt</p>
                        <p className='reportCardTitleValueText'>{this.state.averageImprovementPerAttempt.toFixed(2)}%</p>
                    </div>
                    <div className='reportCardTitleValueContainer'>
                        <p className='reportCardTitleValueText'>Average Improvement Per Day</p>
                        <p className='reportCardTitleValueText'>{this.state.averageImprovementPerDay.toFixed(2)}%</p>
                    </div>
                </div>
            </div>
        )
    }
}
