import React from 'react'
import './BarGraphElement.css'

export default function BarGraphElement(props) {
    return (
        <div className='barGraphElementWrapper'>
            <div className='barGraphBarContainer'>
                <p className='barGraphBarPercentage'>{props.primary}%</p>
                <div className='barGraphBar' style={{backgroundColor: '#D1ECF1', height: `${props.primary * 1.4}px`}}/>
            </div>
            <div className='barGraphBarContainer'>
                <p className='barGraphBarPercentage'>{props.secondary}%</p>
                <div className='barGraphBar' style={{backgroundColor: '#EEEEEE', height: `${props.secondary * 1.4}px`}}/>
            </div>
            <p className='barGraphCaseIdText'>{props.caseId}</p>
        </div>
    )
}
