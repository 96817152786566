import React from 'react'
import './ExitCasePopup.css'

export default function ExitCasePopup(props) {
    return (
        <div className={`exitCasePageContainer ${props.displayExitCasePrompt ? '' : 'hideExitCasePrompt'}`}>
            <div className='exitCaseBackdrop' />
            <div className='exitCaseContainer'>
                <div className='exitCaseHeaderContainer'>
                    <p className='exitCaseHeaderText'>Exit Case?</p>
                </div>
                <div className='exitCaseBodyContainer'>
                    <p className='exitCaseBodyText'>Are you sure you want to exit the case? Your progress will be saved to continue at a later point.</p>
                </div>
                <div className='exitCaseFooterContainer'>
                    <button className='exitCaseFooterButton' onClick={props.cancelExitCase}>Cancel</button>
                    <button className='exitCaseFooterButton' onClick={props.confirmExitCase}>Exit Case</button>
                </div>
            </div>
        </div>
    )
}
