
import ConvertMountainTimeToLocal from "./ConvertMountainTimeToLocal"

const formatTimeString = (dateString, timezone) => {
    let formattedDate
    if (timezone)
        formattedDate = new Date(ConvertMountainTimeToLocal(dateString, timezone))
    else
        formattedDate = new Date(dateString)
    let hours = formattedDate.getHours()
    let minutes = formattedDate.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12
    minutes = minutes < 10 ? '0' + minutes : minutes
    let strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
}

export default formatTimeString