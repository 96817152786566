import React from 'react'
import TermsOfServiceText from './TermsOfServiceText'

export default function TermsOfService(props) {
    return (
        <div className='login-legal-box'>
            <div className='login-legal-header-container'>
                <h3 className='login-legal-header'>Terms of Service</h3>
            </div>
            <div className='login-legal-body-container'>
                <p className='login-legal-text'>
                    <TermsOfServiceText />
                </p>
            </div>
            <div className='login-legal-footer-container'>
                <input className='login-button' type='button' value='Close' onClick={props.closeTermsOfService}/>
            </div>
        </div>
    )
}
