import React from "react";
import "./LineGraph.css";

import { Line } from "react-chartjs-2";

export default function LineGraph(props) {
    const data = {
        labels: props.data,
        datasets: [
            {
                data: props.data.map((value) => Math.round(Number(value.AverageDayGrade))),
                fill: true,
                backgroundColor: "rgba(67, 184, 206, 0.15)",
                borderColor: "rgba(24, 112, 128, 1)"
            }
        ]
    };

    return (
        <div className="LineGraphContainer">
            <Line
                data={data}
                height={250}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return `${context.raw}%`
                                },
                                title: function(context) {
                                    let date = new Date(props.data[context[0].dataIndex].Date)
                                    let day = date.getDate()
                                    let month = date.getMonth() + 1
                                    let year = `${date.getFullYear()}`
                                    return `${month}/${day}/${year.substring(2)}`
                                }
                            }
                        }
                    },
                    layout: {
                        padding: {
                            top: 20,
                            left: 10,
                            right: 25,
                            bottom: 0
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                callback: function(value, index, values) {
                                    return value + '%';
                                }
                            },
                            // max: 100,
                            // min: 0,
                            grid: {
                                display: false
                            },
                            title: {
                                text: "Avg Daily Performance",
                                display: true
                            },
                        },
                        x: {
                            grid: {
                                display: false
                            },
                            title: {
                                text: "Date",
                                display: true
                            },
                            ticks: {
                                maxTicksLimit: 6,
                                callback: function(value, index, values) {
                                    let date = new Date(data.labels[index].Date)
                                    let day = date.getDate()
                                    let month = date.getMonth() + 1
                                    let year = `${date.getFullYear()}`
                                    return `${month}/${day}/${year.substring(2)}`
                                }
                            }
                        }
                    }
                }}
            />
        </div>
    );
}
