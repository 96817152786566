import React from 'react'
import './SimulationFooter.css'

export default function SimulationFooter(props) {
    return (
        <div className='simulationFooterWrapper'>
            <div className='simulationFooterButtonsWrapper'>
                <button className='simulationBodyLabPanelsButton' onClick={props.displayLabPanels}>LAB PANELS</button>
            </div>
            <div className='simulationFooterContainer'>
                <button className='simulationFooterExitCaseButton' onClick={props.displayExitCasePrompt}>Exit Case</button>
                <button className='simulationFooterCommentButton' onClick={props.showComments}>COMMENT</button>
                {!props.submitStep ?
                    <button className='simulationFooterSubmitButton' onClick={props.updateSubmitStep}>SUBMIT</button>
                :
                    <button className='simulationFooterOkButton' onClick={props.submitAnswer}>OK</button>
                }
            </div>
        </div>
    )
}
