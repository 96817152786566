import React from 'react'
import './Comments.css'

export default function Comments(props) {
    return (
        <div className={`commentsPageWrapper ${props.displayCommentsPopup ? '' : 'hideCommentsPopup'}`}>
            <div className='commentsBackdrop' onClick={props.closeComments}/>
            <div className='commentsContainer'>
                <div className='commentsHeaderWrapper'>
                    <h4 className='commentsHeaderText'>Comments</h4>
                </div>
                <textarea value={props.comments} onChange={props.updateComments} className='commentsTextArea'/>
                <div className='commentsFooterWrapper'>
                    <button className='commentsCloseButton'onClick={props.closeComments}>Close</button>
                </div>
            </div>
        </div>
    )
}
