

export const cardTransition = {
    in: {
        scaleY: 1,
        rotateY: 0
    },
    out: {
        scaleY: 0.95,
        rotateY: 90
    },
    initial: {
        scaleY: 0.95,
        rotateY: 90
    }
}

export const pageTransition = {
    in: {
        x: '0%',
        y: '0%',
        opacity: 1,
    },
    out: {
        x: '-1%',
        y: '0%',
        opacity: 0,
    },
    initial: {
        x: '1%',
        y: '0%',
        opacity: 0,
    }
}

export const loginContainerTransition = {
    in: {
        x: '-50%',
        y: '-50%',
        opacity: 1,
    },
    out: {
        x: '-48%',
        y: '-50%',
        opacity: 0,
    },
    initial: {
        x: '-52%',
        y: '-50%',
        opacity: 0,
    }
}

export const backdropTransition = {
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
    initial: {
        opacity: 0,
    }
}

export const settingsDrawerTransition = {
    in: {
        y: '0%',
        transition: 'ease-out'
    },
    out: {
        y: '100%',
        transition: 'ease-in'
    },
    initial: {
        y: '100%',
        transition: 'ease-out'
    }
}

export const popupTransition = {
    in: {
        y: '-50%',
        x: '-50%',
        scale: 1,
        zIndex: 51,
        opacity: 1
    },
    out: {
        y: '-47.5%',
        x: '-50%',
        scale: 0.97,
        zIndex: 51,
        opacity: 0
    },
    initial: {
        y: '-47.5%',
        x: '-50%',
        scale: 0.97,
        zIndex: 51,
        opacity: 0
    }
}

export const appearTransition = {
    in: {
        scale: 1,
        zIndex: 51,
        opacity: 1
    },
    out: {
        scale: 0.97,
        zIndex: 51,
        opacity: 0
    },
    initial: {
        scale: 0.97,
        zIndex: 51,
        opacity: 0
    }
}

export const openTop = {
    in: {
        translateY: '0%',
        translateX: '-50%',
        scale: 1,
        opacity: 1
    },
    out: {
        translateY: '-5%',
        translateX: '-50%',
        scale: 0.9,
        opacity: 0,
    },
    initial: {
        translateY: '-5%',
        translateX: '-50%',
        scale: 0.9,
        opacity: 0,
    }
}

export const transitionDuration = .15
export const pageTransitionDuration = .2
export const backdropTransitionDuration = .15
export const popupTransitionDuration = .1
