import React from 'react'
import './CaseList.css'
import CaseSelect from './Components/CaseSelect/CaseSelect'
import IncompleteCasePopup from './Components/IncompleteCasePopup/IncompleteCasePopup'
import logFetchError from '../../Functions/LogFetchError'
import fetchSubmitLogs from '../../Functions/FetchSubmitLogs'

class CaseList extends React.Component {
	
	constructor(props) {
		super(props);

		let selectedCase = 1;
		if (this.props.selectedCase) {
			selectedCase = this.props.selectedCase;
		}
		
		this.state = {
			selectedCase: selectedCase,			// default selected case
			yield: false,				// show high yield count column, hard coded toggle
			displayIncompleteCases: false,
			incompleteCaseList: [],
			selectedIncompleteCase: 0,
			startNewCaseFunction: null
		}

		this.viewCaseGradesClick = this.viewCaseGradesClick.bind(this);
		this.selectRandomClick = this.selectRandomClick.bind(this);
		this.selectRandomIncompleteClick = this.selectRandomIncompleteClick.bind(this);
		this.runCaseClick = this.runCaseClick.bind(this);
		this.sortTableClick = this.sortTableClick.bind(this);
		this.reviewLaterToggle = this.reviewLaterToggle.bind(this);
	}
	
	/*
	automatically make listcases api call
	*/
	componentDidMount() {
		if (!(this.props.caseListSort === 'id' && this.props.caseListReversed === false)) {
			this.sortTableClick(this.props.caseListSort, true)
		}
	}

	viewCaseGradesClick() {
		// only view case grades if selected case has been completed
		let selectedCaseIndex = 0;
		for (let i = 0; i < this.props.unfilteredCaseList.length; i++) {
			if (this.props.unfilteredCaseList[i].ID === this.props.selectedCase) {
				selectedCaseIndex = i;
				break;
			}
		}

		if (!this.props.unfilteredCaseList[selectedCaseIndex].COMPLETED) {
			return
		}

		let reviewLater = this.props.unfilteredCaseList[selectedCaseIndex].ReviewLaterFlag;

		this.props.viewCaseGrades(this.props.selectedCase, reviewLater)
	}

	/*
	toggle the Review Later column checkbox
	searches through array by id to toggle review later
	*/
	reviewLaterToggle(id) {
		let tempJson = this.props.unfilteredCaseList;
		for (let i = 0; i < tempJson.length; i++) {
			if (tempJson[i].ID === id) {
				tempJson[i].ReviewLaterFlag = !tempJson[i].ReviewLaterFlag;
			}
		}
		this.setState({caseListJson: tempJson});
	}
	
	/*
	randomly generate a case id to select
	*/
	selectRandomClick() {
		let randomCase = this.props.unfilteredCaseList[Math.floor(Math.random()*this.props.unfilteredCaseList.length)];
		this.props.setSelectedCase(randomCase.ID, () => {
			// Scroll case into view
			let selectedCase = document.getElementById(`case-${this.props.selectedCase}`);
			if (selectedCase) {
				// smooth scroll case into center of view
				selectedCase.scrollIntoView({behavior: 'smooth', block: 'center'});
			}
		})
	}
	
	/*
	randomly select a case from the incomplete cases array
	*/
	selectRandomIncompleteClick() {
		let randomCase
		do {
			randomCase = this.props.unfilteredCaseList[Math.floor(Math.random()*this.props.unfilteredCaseList.length)];
		} while (randomCase.COMPLETED)

		this.props.setSelectedCase(randomCase.ID, () => {
			// Scroll case into view
			let selectedCase = document.getElementById(`case-${this.props.selectedCase}`);
			if (selectedCase) {
				// smooth scroll case into center of view
				selectedCase.scrollIntoView({behavior: 'smooth', block: 'center'});
			}
		})
	}
	
	/*
	run the case simulation
	passes the caseData Json up to App
	*/
	runCaseClick() {
		if (this.props.selectedCase) {
			for (let i = 0; i < this.props.unfilteredCaseList.length; i++) {
				if (this.props.unfilteredCaseList[i].ID === this.props.selectedCase) {
					let table = document.querySelector('#caseListTable')
					this.props.setCurrentScrollPosition(table.scrollTop)
					this.props.fetchStartCase(this.props.unfilteredCaseList[i], this.incompleteStartDisplayHandler);
				}
			}
		}
	}

	incompleteStartDisplayHandler = (ialist, callBack) => {
		this.setState({displayIncompleteCases: true, startNewCaseFunction: callBack, incompleteCaseList: ialist})
	}

	/*
	sort cases in table by column
	every column uses the same sorting algorithm except for review
	*/
	sortTableClick(column, mounting = false) {
		let tempArray = this.props.unfilteredCaseList

		if (!this.state.isCaseListReversed && this.state.caseListSort === column) {
			this.props.changeCaseListSort(column, true)
			return this.setState({caseListJson: tempArray.reverse(), isCaseListReversed: true})
		}

		tempArray.sort((a, b) => a.ID - b.ID)

		switch(column) {
			case 'review':
				tempArray.sort((a, b) => b.ReviewLaterFlag - a.ReviewLaterFlag)
				break;
			case 'title':
				tempArray.sort((a, b) => {
					if(a.TITLE < b.TITLE) { return -1; }
					if(a.TITLE > b.TITLE) { return 1; }
					return 0;
				})
				break;
			case 'category':
				tempArray.sort((a, b) => {
					if(a.CASECATEGORYDESC < b.CASECATEGORYDESC) { return -1; }
					if(a.CASECATEGORYDESC > b.CASECATEGORYDESC) { return 1; }
					return 0;
				})
				break;
			case 'diagnosis':
				tempArray.sort((a, b) => {
					if(a.DIAGNOSIS < b.DIAGNOSIS) { return -1; }
					if(a.DIAGNOSIS > b.DIAGNOSIS) { return 1; }
					return 0;
				})
				break;
			case 'time':
				tempArray.sort((a, b) => {
					if(a.TIMEMODEDESC < b.TIMEMODEDESC) { return -1; }
					if(a.TIMEMODEDESC > b.TIMEMODEDESC) { return 1; }
					return 0;
				})
				break;
			case 'completed':
				tempArray.sort((a, b) => new Date(b.LastCompletionDate) - new Date(a.LastCompletionDate))
				break;
			case 'yield':
				tempArray.sort((a, b) => b.HIGHYIELDCOUNT - a.HIGHYIELDCOUNT)
				break;
			case 'grade':
				tempArray.sort((a, b) => b.AvgGradeForCase - a.AvgGradeForCase)
				break;
			default:
				break;
		}

		if (mounting === true && this.props.caseListReversed) {
			tempArray.reverse()
		}

		this.props.changeCaseListSort(column, false)
		this.setState({caseListJson: tempArray, caseListSort: column, isCaseListReversed: false});

		// if (column === 'review') {
		// 	if (this.state.lastSort === 'review') {
		// 		tempArray = tempArray.reverse();
		// 		this.setState({lastSort: false});
		// 	}
		// 	else {
		// 		for (let i = 1; i < tempArray.length; i++) {
		// 			if (tempArray[i].ReviewLaterFlag) {
		// 				tempArray.unshift(tempArray.splice(i, 1)[0]);
		// 			} 
		// 		}
		// 		this.setState({lastSort: 'review'});
		// 	}
		// }
		// else {
		// 	let alreadySorted = true;// track if the array is already sorted
		// 	let sorted = false;// track when the algorithm is finished
		// 	while (!sorted) {
		// 		sorted = true;
		// 		for (let i = 1; i < tempArray.length; i++) {
					
		// 			// if not sorted by column
		// 			if (
		// 				(column === 'id' && tempArray[i-1].ID > tempArray[i].ID) ||
		// 				(column === 'title' && tempArray[i-1].TITLE.charAt(0) > tempArray[i].TITLE.charAt(0)) ||
		// 				(column === 'category' && tempArray[i-1].CASECATEGORYDESC.charAt(0) > tempArray[i].CASECATEGORYDESC.charAt(0)) ||
		// 				(column === 'diagnosis' && tempArray[i-1].DIAGNOSIS.charAt(0) > tempArray[i].DIAGNOSIS.charAt(0)) ||
		// 				(column === 'time' && tempArray[i-1].TIMEMODEDESC.charAt(0) > tempArray[i].TIMEMODEDESC.charAt(0)) ||
		// 				(column === 'completed' && Date.parse(tempArray[i-1].LastCompletionDate) < Date.parse(tempArray[i].LastCompletionDate)) ||
		// 				(column === 'yield' && tempArray[i-1].HIGHYIELDCOUNT > tempArray[i].HIGHYIELDCOUNT) ||
		// 				(column === 'grade' && tempArray[i-1].AvgGradeForCase > tempArray[i].AvgGradeForCase)
		// 			) {

		// 				// if out of order put in order
		// 				sorted = false;
		// 				alreadySorted = false;
		// 				//switch
		// 				let temp = tempArray[i-1];
		// 				tempArray[i-1] = tempArray[i];
		// 				tempArray[i] = temp;
		// 			} 
		// 		}
		// 	}
		
		// 	if (alreadySorted) {
		// 		tempArray = tempArray.reverse();
		// 	}
		// }

		// this.setState({caseListJson: tempArray});
	}

	handlePageChange = (page) => {
        this.props.changePage(page)
    }

	render() {

		let yieldColumn;
		let titleColumn;
		let diagnosisColumn;

		if (this.state.yield) {
			yieldColumn = <div className='column yield' onClick={this.sortTableClick.bind(this, 'yield')}>High Yield Count</div>
		}

		if (this.props.showCaseTitle) {
			titleColumn = <div className='column title' onClick={this.sortTableClick.bind(this, 'title')}>Title</div>;
		}

		if (this.props.showCaseDiagnosis) {
			diagnosisColumn = <div className='column diagnosis' onClick={this.sortTableClick.bind(this, 'diagnosis')}>Diagnosis</div>;
		}

		let caseList;
		if (this.props.unfilteredCaseList !== undefined) {
			caseList = this.props.unfilteredCaseList.map((json) =>
				<CaseSelect
					data={json}
					title={this.props.showCaseTitle} 
					diagnosis={this.props.showCaseDiagnosis} 
					yield={this.state.yield}
					key={json.ID}
					selectedCase={this.props.selectedCase}
					caseSelectClick={this.props.setSelectedCase}
					setAuthenticationError={this.props.setAuthenticationError}
					scroll={this.state.scroll}
					userData={this.props.userData}
					userProfile={this.props.userProfile}
					reviewLaterToggle={this.reviewLaterToggle}
					route={this.props.route}
					setFetchOutstanding={this.props.setFetchOutstanding}
				/>
			);
		}

		let viewGradesStyle;
		if (this.state.incompleteCases && this.state.incompleteCases.includes(this.props.selectedCase)) {
			viewGradesStyle = 'fade';
		}

		return (
			<div className='case-list'>
				{this.state.displayIncompleteCases &&
					<IncompleteCasePopup
						userProfile={this.props.userProfile}
						selectedIncompleteCase={this.state.selectedIncompleteCase}
						startNewCaseFunction={this.state.startNewCaseFunction}
						closeIncompleteCases={() => this.setState({displayIncompleteCases: false})}
						incompleteCaseList={this.state.incompleteCaseList}
						changeSelectedCase={(newIndex) => this.setState({selectedIncompleteCase: newIndex})}
						confirmIncompleteCaseStart={this.props.confirmIncompleteCaseStart}
					/>
				}
				<div className='page-header'>
					<div className='page-title'>CDM Case Selection</div>
					<p className='page-error'>{this.props.caseListHeaderError}</p>
					<div><input type='button' value='Account Settings' onClick={() => this.handlePageChange('accountSettings')}/></div>
				</div>
				<div className='table' style={{display: 'grid', gridTemplateRows: 'auto 1fr'}}>
					<div className='table-row header' style={{height: 'auto', minHeight: '21px'}}>
						<div className='column id' onClick={this.sortTableClick.bind(this, 'id')}>Id</div>
						<div className='wrapper title-category-diagnosis-time'>
							{titleColumn}
							<div className='column category' onClick={this.sortTableClick.bind(this, 'category')}>Category</div>
							{diagnosisColumn}
							{/* <div className='column time' onClick={this.sortTableClick.bind(this, 'time')}>Time Limit</div> */}
						</div>
						<div className='wrapper completed-review-grade'>
							<div className='column completed' onClick={this.sortTableClick.bind(this, 'completed')}>Completed</div>
							{yieldColumn}
							<div className='column review' onClick={this.sortTableClick.bind(this, 'review')}>Review Later</div>
							<div className='column grade' onClick={this.sortTableClick.bind(this, 'grade')}>Average Grade</div>
						</div>
						{/* <div className='spacer'></div> */}
					</div>
					<div className='table-content' id='caseListTable' style={{height: '100%'}}>
						{caseList}
					</div>
				</div>
				<div className='table-options'>
					<div onClick={this.props.togglePracticeMode}>
						<input className='table-option-checkbox' type='checkbox' checked={this.props.practiceMode}/>
						<label>Practice mode</label>
					</div>
					<div onClick={this.props.toggleShowCaseTitle}>
						<input className='table-option-checkbox' type='checkbox' checked={this.props.showCaseTitle}/>
						<label>Show Case Title</label>
					</div>
					<div onClick={this.props.toggleShowCaseDiagnosis}>
						<input className='table-option-checkbox' type='checkbox' checked={this.props.showCaseDiagnosis}/>
						<label>Show Case Diagnosis</label>
					</div>
				</div>
				<div className='options'>
					<div className='simulation-menu-options'>
						<div onClick={this.props.timedExamToggle}>
							<input type='checkbox' checked={this.props.timedExam}/>
							<label>Timed Exam</label>
						</div>
					</div>
					<div className='buttons'>
						<div className='program-options'>
							<input className='button' type='button' value='Select Random Case' onClick={this.selectRandomClick}/>
							<input className='button' type='button' value='Select Random Incomplete Case' onClick={this.selectRandomIncompleteClick}/>
						</div>
						<div className='program-options'>
							<input className='button' type='button' value='Report Card' onClick={this.props.viewReportCard}/>
							<input className={'button ' + viewGradesStyle} type='button' value='View Case Grades' onClick={this.viewCaseGradesClick}/>
							<input className='button' type='button' value='Run Case' onClick={this.runCaseClick}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CaseList;
