

const logFetchError = (error, userProfile, userData, functionName) => {
    if (process.env.REACT_APP_ERROR_REPORT === "true") {
        // Create error log
        let log = {
            browser: getBrowserDetails(),
            networkError: `${error}`,
            operatingSystem: navigator.platform,
            time: `${new Date()}`,
            customerId: userData ? userData.CustomerId : null,
            token: userData ? userData.Token : null,
            functionWhereErrorOccured: functionName,
            accountCreationDate: userProfile ? userProfile.accountCreationDate : null,
            caseCompletionLimit: userProfile ? userProfile.caseCompletionLimit : null,
            demo: userProfile ? userProfile.demo : null,
            firstName: userProfile ? userProfile.firstName : null,
            lastName: userProfile ? userProfile.lastName : null,
            subscriptionEndDate: userProfile ? userProfile.subscriptionEndDate : null,
            userName: userProfile ? userProfile.userName : null
        }
        
        if (window.localStorage.length === 0) {
            window.localStorage.setItem('ccsLog 0', JSON.stringify(log))
        } else {
            let count = 0
            for(let i = 0; i < localStorage.length; ++i) {
                let tempStr = localStorage.key(i)
                if (tempStr.includes('ccsLog')) {
                    ++count
                }
            }
            window.localStorage.setItem(`ccsLog ${count}`, JSON.stringify(log))
        }
    }
}

const getBrowserDetails = () => {

    navigator.sayswho= (function(){
        var ua= navigator.userAgent, tem, 
        M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
        }
        if(M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();

    return navigator.sayswho
}

export default logFetchError