import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha';

export default function LoginPage(props) {
    return (
        <div className='login-box'>
            {props.captcha &&
                <ReCAPTCHA
                    sitekey='6LerAAcaAAAAAGa8HfJDuk1sHlj_9niQfNrn0TNB'
                    onChange={props.completeCaptcha}
                />
            }
            {props.demoMessage && <p style={{margin: '0px', marginBottom: '10px', color:'green'}}>Demo Account Created. We've emailed your login and password. If you did not receive the e-mail, please check your spam folder.</p>}
            <div className='login-box-element'>
                <div>Email:</div>
                <input className='login-field' type='email' value={props.email} onChange={props.emailChange}/>
            </div>
            <div className='login-box-element'>
                <div>Password:</div>
                <input className='login-field' type='password' value={props.password} onChange={props.passwordChange} onKeyDown={(value) => value.key === 'Enter' && props.loginClick()}/>
            </div>
            <div className='login-message'>{props.loginError}</div>
            <div className='login-box-element'>
                <input className='login-button' type='button' value='Sign in' onClick={props.loginClick}/>
            </div>
            <div className='login-legal-information-container' onClick={props.viewTermsOfService}>
                <p className='login-legal-information-text'><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" style={{width: '16px', position: 'relative', top: '1px'}} className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg> By logging in, you agree to our terms and conditions</p>
            </div>
            <div className='login-box-element forgot' onClick={props.forgotPasswordClick}>
                <div>{'Forgot password?'}</div>
            </div>
            <div className='login-box-element demo' onClick={props.demoClick}>
                <div className='white-space'>{'Want to try 2 free cases? Sign up for our demo.'}</div>
            </div>
        </div>
    )
}
