import React from 'react'
import './LabPanelItem.css'

export default function LabPanelItem(props) {
    let labPanelItems = ['pH', 'PO2', 'PCO2', 'Bicarbonate', 'Oxygen saturation']

    return (
        <div className='labPanelItemContainer'>
            <p className='labPanelItemTitle'>{props.title}</p>
            {props.contents.map((value, index) => (
                <p className='labPanelItemText' key={`itemText${index}`}>{value}</p>
            ))}
            {props.indentedContents &&
                props.indentedContents.map((value, index) => (
                    <p className='labPanelItemText labPanelIndentedItemText' key={`indentedItemText${index}`}>{value}</p>
                ))
            }
        </div>
    )
}
