import React from 'react'
import './AnswerPopup.css'

export default function AnswerPopup(props) {

    const getAnswerInfo = () => {
        let correctAnswersArray = []
        props.gradeOutput.forEach((answerOutput, answerIndex) => {
            correctAnswersArray.push({
                answer: answerOutput.OrderName,
                explanation: answerOutput.Explanation,
                correct: answerOutput.EventName === 'Correctly Ordered' ? true : false
            })
        })
        return correctAnswersArray
    }

    const getMultipleChoiceOption = (optionIndex, questionId) => {
		let answer
		props.simulationData.ao.forEach((answerValue, answerIndex) => {
			if (answerValue.ANSWERID === optionIndex) {
				answer = answerValue.ANSWER
			}
		})
		return answer
	}

    return (
        <div className='answerPopupPageWrapper'>
            <div className='answerPopupBackdrop' />
            <div className='answerPopupContainer'>
                <div className='answerPopupHeaderContainer'>
                    <p className='answerPopupHeaderText'>Correct Answers for question {props.currentQuestion}</p>
                </div>
                <div className='answerPopupBodyContainer'>
                    {/* {getAnswerInfo().map((answerValue, answerIndex) => (
                        <div className='answerPopupAnswerContainer' key={answerIndex}>
                            {answerValue.correct ?
                                <div className='answerPopupCorrectAnswer' />
                            :
                                <div className='answerPopupIncorrectAnswer' />
                            }
                            <p className='answerPopupAnswerText'>{answerValue.answer}</p>
                            <p className='answerPopupExplanationText'>{answerValue.explanation}</p>
                        </div>
                    ))} */}
                    <div className='grading-feedback-category' key={`gradingCategory${props.currentQuestion - 1}`}>
                        <div className='grading-feedback-category-header'>
                            Question {props.currentQuestion} ({props.gradeOutput[0].grade ? `${props.gradeOutput[0].grade.toFixed(2)}%` : '0%'})
                        </div>
                            <div className={`grading-feedback-event userEntry`}>
                                <div className='grading-feedback-event-header'>Your answer</div>
                                <ul>
                                    {props.gradeOutput[0].PreviousInput[0].CustomerAnswersBlank ? props.gradeOutput[0].PreviousInput[0].CustomerAnswersBlank.map((inputValue, inputIndex) => (
                                        <li key={`Question${props.currentQuestion - 1}Answer${inputIndex}`}>{inputValue === "" ? <b>Option left blank</b> : inputValue}</li>
                                    ))
                                    : props.gradeOutput[0].PreviousInput[0].CustomerAnswerMC.map((inputValue, inputIndex) => (
                                        <li key={`Question${props.currentQuestion - 1}Answer${inputIndex}`}>{getMultipleChoiceOption(inputValue, props.gradeOutput[0].QuestionId)}</li>
                                    ))}
                                </ul>
                            </div>
                            {props.gradeOutput[0].GradeOut.map((gradedValue, gradedIndex) => (
                                <div key={`GradeResult${props.currentQuestion - 1}-${gradedIndex}`} className={`grading-feedback-event ${gradedValue.GradeColor === 'Correct - Green' ? 'correct' : gradedValue.GradeColor === 'Neutral - Blue' ? 'optional' : 'incorrect'}`}>
                                    <div className='grading-feedback-event-header'>{gradedValue.EventName}</div>
                                    <ul>
                                        <li>{gradedValue.OrderName}</li>
                                    </ul>
                                    <div><span className='grading-feedback-event-reason'>{'Reason: '}</span>{gradedValue.Explanation}</div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className='answerPopupFooterContainer'>
                    <button className='answerPopupFooterButton' onClick={props.continueCase}>{((props.currentQuestion === props.simulationData.answerList.length) || props.caseEnded) ? 'Complete Case' : 'Next Question'}</button>
                </div>
            </div>
        </div>
    )
}
