import React from 'react'
import LabPanelItem from './Components/LabPanelItem/LabPanelItem'
import './LabPanels.css'

export default function LabPanels(props) {
    return (
        <div className={`labPanelsPageWrapper ${props.displayLabPanels ? '' : 'hideLabPanelsPopup'}`}>
            <div className='labPanelsBackdrop' onClick={props.closeLabPanels}/>
            <div className='labPanelsContainer'>
                <div className='labPanelsHeaderWrapper'>
                    <h4 className='labPanelsHeader'>Lab Panel</h4>
                    <button className='labPanelsCloseButton' onClick={props.closeLabPanels}>&#10005;</button>
                </div>
                <div className='labPanelContentWrapper'>
                    <div className='labPanelsBodyWrapper'>
                        <p className='labPanelsTitleText'>APPROVED LABORATORY TEST PANELS</p>
                    </div>
                    <LabPanelItem
                        title={'Arterial Blood Gases'}
                        contents={['pH', 'PO2', 'PCO2', 'Bicarbonate', 'Oxygen saturation']}
                    />
                    <LabPanelItem
                        title={'Basic Metabolic Panel'}
                        contents={['BUN', 'Creatinine', 'Glucose', 'Sodium', 'Chloride', 'Potassium', 'Bicarbonate']}
                    />
                    <LabPanelItem
                        title={'Complete Blood Count'}
                        contents={['Leukocyte count', 'Erythrocyte count', 'Hemoglobin', 'Hematocrit', 'Mean corpuscular volume', 'Mean corpuscular hemoglobin', 'Mean corpuscular hemoglobin concentration', 'Red blood cell distribution width', 'Platelet count', 'Differential']}
                        indentedContents={['Band neutrophils', 'Segmented neutrophils', 'Lymphocytes', 'Monocytes', 'Eosinophils', 'Basophils']}
                    />
                    <LabPanelItem
                        title={'Electrolyte Panel'}
                        contents={['Sodium', 'Chloride', 'Potassium', 'Bicarbonate']}
                    />
                    <LabPanelItem
                        title={'Hepatic Panel'}
                        contents={['Aspartate aminotransferase', 'Alanine aminotransferase', 'Alkaline phosphatase', 'Total bilirubin', 'Direct bilirubin']}
                    />
                    <LabPanelItem
                        title={'Lipid Panel'}
                        contents={['Total cholesterol', 'HDL cholesterol', 'LDL cholesterol', 'Triglycerides']}
                    />
                    <LabPanelItem
                        title={'Serum Immunoglobulins'}
                        contents={['IgA', 'IgD', 'IgE', 'IgG', 'IgM']}
                    />
                    <LabPanelItem
                        title={'Urinalysis'}
                        contents={['pH', 'Specific gravity', 'Protein', 'Glucose', 'Ketones', 'Bilirubin', 'Nitrites', 'Leukocyte esterase', 'Blood']}
                    />
                </div>
            </div>
        </div>
    )
}
