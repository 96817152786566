import React from 'react'

export default function InputAnswer(props) {
    return (
        <div>
            <p className='simulationBodyText'>{props.simulationData.qo[props.currentQuestion - 1].QUESTION}</p>
            <p className='simulationBodyText warningText'>You may list up to {props.simulationData.qo[props.currentQuestion - 1].MAXACCEPTEDANSWERS} responses. You may need to use the scroll bar 
            at the right to view all available text boxes.
            </p>
            <div className='simulationBodyAnswersContainer'>
                {props.answerList[props.currentQuestion - 1].map((value, index) => (
                    <div className='simulationBodyAnswerWrapper' key={`inputAnswer${index}`}>
                        <h3 className='simulationBodyHeader simulationBodyAnswerNumber'>{index + 1}</h3>
                        <input className='simulationBodyAnswerInput' value={value} onChange={(e) => props.modifyInputAnswer(e, index)}/>
                        <p className='simulationBodyRemainingCharactersText'>{100 - value.length}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
