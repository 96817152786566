
import ConvertMountainTimeToLocal from "./ConvertMountainTimeToLocal"

const formatDateString = (dateString, timezone) => {
    let formattedDate
    if (timezone)
        formattedDate = new Date(ConvertMountainTimeToLocal(dateString, timezone))
    else
        formattedDate = new Date(dateString)
    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let month = formattedDate.getMonth() + 1
    let day = formattedDate.getDate()
    let year = formattedDate.getFullYear()
    let monthName = MONTHS[month - 1]
    return `${monthName} ${day}, ${year}`
}

export default formatDateString