

const ConvertMountainTimeToLocal = (dateToConvert, userTimezone) => {
    if (userTimezone === "" || userTimezone === "-7")
      return dateToConvert

    let timezoneOffset = -7 - parseInt(userTimezone)
    timezoneOffset *= -1
    let convertedDate = new Date(new Date(dateToConvert).getTime() + timezoneOffset * 3600000)
    return convertedDate
}

export default ConvertMountainTimeToLocal