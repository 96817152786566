import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import './IncompleteCasePopup.css'
import { motion } from 'framer-motion/dist/framer-motion'
import MDSpinner from 'react-md-spinner'

export default class IncompleteCasePopup extends Component {
    constructor() {
        super()
        this.state = {
            optionSelected: -1,
            incompleteAttempts: [
                "May 5th, 2022, 10:22 AM",
                "May 8th, 2022, 3:56 PM",
                "May 8th, 2022, 3:56 PM",
                "May 8th, 2022, 3:56 PM",
                "May 8th, 2022, 3:56 PM",
                "May 8th, 2022, 3:56 PM",
                "May 8th, 2022, 3:56 PM"
            ]
        }
    }

    formatTime = (rawTime) => {
        let date = new Date(rawTime)
        const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        let month = MONTHS[date.getMonth()]
        let day = date.getDate()
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let dayPeriod = 'AM'

        if (hours > 12) {
            hours -= 12
            dayPeriod = 'PM'
        } else if (hours === 12) {
            dayPeriod = 'PM'
        } else if (hours === 0) {
            hours = 12
        }

        let suffix = ""

        if (day % 10 === 1 && day !== 11)
            suffix = 'st'
        else if (day % 10 === 2 && day !== 12)
            suffix = 'nd'
        else if (day % 10 === 3 && day !== 13)
            suffix = 'rd'
        else
            suffix = 'th'
    
        return `${month} ${day}${suffix}, ${year}, ${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${dayPeriod}`
    }

    render() {
        return (
            <div className='incompleteCasePopupPageContainer' key='askjdhfoisdf'>
                <motion.div
                    key='incompleteCasePopupBackdrop'
                    initial={this.props.animationsDisabled ? false : {opacity: 0}}
                    animate={this.props.animationsDisabled ? false : {opacity: 1}}
                    exit={this.props.animationsDisabled ? false : {opacity: 0}}
                    transition={{duration: 0.2}}
                    className='incompleteCasePopupBackdrop'
                    onClick={this.props.closePopup}
                />
                <motion.div
                    className='incompleteCasePopupContainer'
                    initial={this.props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
                    animate={this.props.animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
                    exit={this.props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
                    transition={{duration: 0.2}}
                    key="incompleteCasePopupContainer"
                >
                    <button className='popupCloseButton' onClick={this.props.closePopup}/>
                    <div className='incompleteCasePopupHeaderWrapper'>
                        <h2 className={`incompleteCasePopupId`}>{this.props.caseInfo.ID}</h2>
                        <h3 className='incompleteCasePopupTitle'>{this.props.showCaseDiagnosis ? this.props.caseInfo.DIAGNOSIS : !this.props.showCaseTitle ? this.props.caseInfo.CASECATEGORY : this.props.caseInfo.TITLE}</h3>
                    </div>
                    {this.props.hideHighYield ?
                        <div className='caseListMetaDetailsWrapper'>
                            <FontAwesomeIcon icon={this.props.getCategoryIcon(this.props.caseInfo.CASECATEGORY)} className={`caseListCaseIcon${this.props.selectedCase === this.props.caseInfo.ID ? ' caseListWhite' : ''}`} />
                            <p className='caseListMetaText'>{this.props.showCaseTitle ? `${this.props.caseInfo.CASECATEGORY}` : ''}</p>
                        </div>
                    :
                        <div className='caseListMetaDetailsWrapper'>
                            <FontAwesomeIcon icon={this.props.getCategoryIcon(this.props.caseInfo.CASECATEGORY)} className={`caseListCaseIcon${this.props.selectedCase === this.props.caseInfo.ID ? ' caseListWhite' : ''}`} />
                            <p className='caseListMetaText'>{this.props.showCaseTitle ? `${this.props.caseInfo.CASECATEGORY} | ` : ''} <strong>{this.props.caseInfo.HIGHYIELDCOUNT} High Yield</strong></p>
                        </div>
                    }
                    <h3 className='incompleteCasePopupHeader'>Continue Previous Attempt?</h3>
                    <p className='incompleteCasePopupText'>If you have left a case without completing it in the past, you can continue the case here. Otherwise, you can start a new case. Your previous incomplete attempts will only be cleared after you complete this case.</p>
                    <div className='incompleteCasePopupOptionsWrapper'>
                        {this.props.incompleteCaseList.map((value, index) => (
                            <div key={value.TimeAttempted} className={`incompleteCasePopupOptionContainer${this.state.optionSelected === index ? ' incompleteCasePopupOptionSelected' : ''}`} onClick={() => this.setState({optionSelected: index})}>
                                <p className='incompleteCasePopupOptionText'>{this.formatTime(value.TimeAttempted)}</p>
                            </div>
                        ))}
                    </div>
                    <div className='incompleteCasePopupButtonWrapper'>
                        {/* {true ? */}
                        {this.props.startNewCaseLoading ?
                            <div className='incompleteCasePopupLoadingWrapper'>
                                <MDSpinner
                                    size={30}
                                    singleColor={this.props.primaryColor}
                                />
                            </div>
                        :
                            <button className='incompleteCasePopupButton' onClick={this.props.startNewCaseFunction}>Start New Case</button>
                        }
                        {/* {true ? */}
                        {this.props.continueCaseLoading ?
                            <div className='incompleteCasePopupLoadingWrapper'>
                                <MDSpinner
                                    size={30}
                                    singleColor={this.props.primaryColor}
                                />
                            </div>
                        :
                            <button className={`incompleteCasePopupButton${this.state.optionSelected === -1 ? ' incompleteCasePopupButtonDisabled' : ' incompleteCasePopupButtonActive'}`} onClick={() => this.props.confirmIncompleteCaseStart(this.state.optionSelected, this.props.incompleteCaseList)}>Continue Case</button>
                        }
                    </div>
                </motion.div>
            </div>
        )
    }
}
